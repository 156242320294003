const state = {
    patternSize: 574.842,
    viewBoxWidth: 1300,
    viewBoxHeight: 1300,
    viewPortWidth: '100%',
    viewPortHeight: '100%',
    svgViewPath: `M0,0 L0,400 L400,400 L400,0 z`,
    backPaperEdit: false,
    backgroundColors: [
        '#FFFFFF', '#545454', '#A6A6A6', '#FF3030',
        '#FF5757', '#FF65C3', '#CB6CE6', '#CB6CE6',
        '#5E17EB', '#7ED957', '#C1FF72', '#FFBD59',
        '#FFEE59', '#000000'
    ],
    selectedTextElement: {},
    cutlines: {
        rounded: 'M-43.077600434413654 23.96536289380731C-43.077600434413654 -4.7333413748667645 -18.030171939164347 -27.99823790716826 1.2713293734520903 -27.998237907168267C1.2713293734520903 -27.998237907168267 482.93235464914073 -27.998237907168267 482.93235464914073 -27.998237907168267C502.2338559617572 -27.998237907168264 527.2812844570051 -4.7333413748667645 527.2812844570051 23.96536289380731C527.2812844570051 23.96536289380731 527.2812844570051 335.7469676996589 527.2812844570051 326.22526094875235C527.2812844570051 364.4456719683314 502.2338559617572 378.18886174972687 482.93235464914073 387.71056850062917C482.93235464914073 387.71056850062917 -8.129140752103211 387.71056850062917 -8.129140752103211 387.71056850062917C-27.430642064719596 378.18886174972687 -43.077600434413654 364.4456719683314 -43.077600434413654 326.22526094875235C-43.077600434413654 326.22526094875235 -43.077600434413654 23.96536289380731 -43.077600434413654 23.96536289380731 ',
        circle: 'M47.55509091504874 203.20355895405282C47.55509091504875 47.0104558843091 216.92057762966982 -50.610233534281015 352.41296700136735 27.486318000591016C415.2952037476453 63.73105370461405 454.0322590301394 130.71408754600714 454.0322590301394 203.20355895405282C454.0322590301394 359.39666202379675 284.6667723155166 457.01735144238626 149.17438294382106 378.920799907516C86.29214619754327 342.6760642034922 47.555090915048744 275.6930303620987 47.55509091504874 203.20355895405282C47.55509091504874 203.20355895405282 47.55509091504874 203.20355895405282 47.55509091504874 203.20355895405282 ',
        square: 'M26.24815408370394 -25.714282530790115L26.24815408370394 421.62377583254363L473.67805197000297 421.62377583254363L473.67805197000297 -25.714282530790115Z ',
        rectangle: 'M-39.99999848259087 -25.714282585923762L-39.99999848259087 367.85716459217366L489.99996857312726 367.85716459217366L489.99996857312726 -25.714282585923762Z'
    }
};

const getters = {
    getPatternSize: (state => state.patternSize),
    getViewBoxWidth: (state => state.viewBoxWidth),
    getViewBoxHeight: (state => state.viewBoxHeight),
    getViewPortWidth: (state => state.viewPortWidth),
    getViewPortHeight: (state => state.viewPortHeight),
    getCutlines: (state => state.cutlines),
    getSvgViewPath: (state => state.svgViewPath),
    getBackPaperEdit: (state => state.backPaperEdit),
    getBackgroundColors: (state => state.backgroundColors),
    getSelectedTextElement: (state => state.selectedTextElement),
};

const actions = {
    setBackPaperEdit: ({ commit }, backPaperEdit) => {
        commit('setBackPaperEdit', backPaperEdit);
    },
    setSelectedTextElement: ({ commit }, selectedTextElement) => {
        commit('setSelectedTextElement', selectedTextElement);
    },
    setSvgViewPath: ({ commit }, svgViewPath) => {
        commit('setSvgViewPath', svgViewPath);
    },
};

const mutations = {
    setBackPaperEdit: (state, backPaperEdit) => {
        state.backPaperEdit = backPaperEdit;
    },
    setSelectedTextElement: (state, selectedTextElement) => {
        state.selectedTextElement = selectedTextElement;
    },
    setSvgViewPath: (state, svgViewPath) => {
        state.svgViewPath = svgViewPath;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
