// import axios from "axios";

export const startScrolling = () => {
    var containers = document.querySelectorAll('.scrolling-container');

    containers.forEach(function (container) {
        var text = container.querySelector('.scrolling-text');
        text.style.animation = '';

        if (text && text.scrollWidth > container.clientWidth) {
            const diff = text.scrollWidth - container.clientWidth;
            const keyframesName = 'scroll-text-' + Math.floor(Math.random() * 1000000);
            const keyframes = `
                @keyframes ${keyframesName} {
                    0% {
                        transform: translateX(calc(-50% + ${diff}px));
                    }
                    50% {
                        transform: translateX(calc(-50% - ${diff}px));
                    }
                    100% {
                        transform: translateX(calc(-50% + ${diff}px));
                    }
                }
            `;
            const styleSheet = document.styleSheets[0];
            styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
            text.style.animation = `${keyframesName} ${(diff / 5) > 2 ? (diff / 5) : 2}s linear infinite`;
        }
    });
};

// const {data: products} = await axios.get('jsons/products.json');

// const {data: shapes} = await axios.get('jsons/shapes.json');
// const {data: productShapes} = await axios.get('jsons/product_shapes.json');

// const {data: materials} = await axios.get('jsons/materials.json');
// const {data: productMaterials} = await axios.get('jsons/product_materials.json');

// const {data: productSizes} = await axios.get('jsons/product_sizes.json');
// const {data: sizes} = await axios.get('jsons/sizes.json');

// const {data: productCoatings} = await axios.get('jsons/product_coating.json');
// const {data: coatings} = await axios.get('jsons/coatings.json');

// const {data: priceQuantities} = await axios.get('jsons/price_quantities.json');

// export const handleSelectedProduct = async (productId) => {
//     const {data: products} = await axios.get('jsons/products.json');
//     const shapes = filterShapes(productId);
//     const materials = filterMaterials(productId);
//     const coatings = filterCoatings(productId, materials[0].id);
// };


// export const filterShapes = async (productId) => {
//     const {data: shapes} = await axios.get('jsons/shapes.json');
//     const {data: productShapes} = await axios.get('jsons/product_shapes.json');

//     const filteredShapes = productShapes?.filter(productShape => productShape.product_id == productId)
//             .map(productShape => shapes.find(shape => shape.id == productShape.shape_id));
//     return filteredShapes;
// }

// export const filterMaterials = async (productId) => {
//     const {data: materials} = await axios.get('jsons/materials.json');
//     const {data: productMaterials} = await axios.get('jsons/product_materials.json');

//     const filteredMaterials = productMaterials?.filter(productMaterial => productMaterial.product_id == productId)
//             .map(productMaterial => materials.find(material => material.id == productMaterial.material_id));
//     return filteredMaterials;
// }

// export const filterCoatings = async (productId, materialId) => {
//         const {data: productCoatings} = await axios.get('jsons/product_coating.json');
//         const {data: coatings} = await axios.get('jsons/coatings.json');

//     const filteredCoatings = productCoatings?.filter(productCoating => productCoating.product_id == productId && productCoating.material_id == materialId)
//             .map(productCoating => coatings.find(coating => coating.id == productCoating.coating_id));
//     return filteredCoatings;
// }
