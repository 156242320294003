// store.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'; // Import vuex-persist

import selectedModule from './modules/selected';
import stickerModule from './modules/sticker';
import stickerPaper from "@/store/modules/sticker-paper";

export default createStore({
    plugins: [createPersistedState({
        key: 'pre_selected',
        paths: ['selection.preselected']
    })],

    modules: {
        selection: selectedModule,
        sticker: stickerModule,
        stickerPaper: stickerPaper
    }
});
