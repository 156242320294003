<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Sticker Material</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3">Change the Sticker Material</p>
        <div class="input-group my-3">
            <span class="input-group-text bg-white pe-0" id="search"><i class="bi bi-search"></i></span>
            <input type="text" class="form-control border-start-0" placeholder="Search for materials"
                aria-label="Search for cliparts" aria-describedby="search">
        </div>
        <div class="flex-1 rounded customized_scrolled">
            <div class="rounded row g-0">
                <div v-for="material in materials" :key="material.id" class="col-6 col-md-4 col-lg-4 p-1">
                    <button @click="handleSelectStickerMaterial(material)"
                        :class="[selectedMaterial.id == material.id ? 'active_selection' : '', 'selection_btn bg-gray-900 p-2 rounded w-100']">
                        <div class="">
                            <img class="material_img w-100" :src="material.thumbnail" alt="">
                        </div>
                        <div class="scrolling-container">
                            <p class="m-0 scrolling-text">{{ material.name }}</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <p>Sticker Coating</p>
            <div class="rounded row g-0">
                <div v-for="coating in coatings" :key="coating.id" class="col-4 p-1">
                    <button @click="handleSelectStickerCoating(coating)"
                        :class="[selectedCoating.id == coating.id ? 'active_selection' : '', 'selection_btn bg-gray-900 p-2 rounded w-100']">
                        <div class="">
                            <img class="coating_img" :src="coating.thumbnail" alt="">
                        </div>
                        <div class="scrolling-container">
                            <p class="m-0 scrolling-text">{{ coating.name }}</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { startScrolling } from '@/assets/js/selection';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();

        const materials = computed(() => store.getters['selection/getMaterials']);
        const selectedMaterial = computed(() => store.getters['selection/getSelectedMaterial']);
        const coatings = computed(() => store.getters['selection/getCoatings']);
        const selectedCoating = computed(() => store.getters['selection/getSelectedCoating']);


        const handleSelectStickerMaterial = async (material) => {
            await store.dispatch('selection/setSelectedMaterial', material);
        }

        const handleSelectStickerCoating = async (coating) => {
            await store.dispatch('selection/setSelectedCoating', coating);
            setTimeout(() => {
                startScrolling();
            }, 1);
        }

        onMounted(() => {
            startScrolling();
        })

        return {
            materials,
            selectedMaterial,
            coatings,
            selectedCoating,
            handleSelectStickerMaterial,
            handleSelectStickerCoating
        }
    }
}
</script>
<style scoped>
.scrolling-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 1rem;
}

.scrolling-text {
    font-size: 0.75rem !important;
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    transform: translateX(-50%);
}

.active_selection {
    background-color: #A370F7 !important;
    color: #212529;
}

.coating_img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}
</style>
