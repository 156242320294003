<template>
    <div
        :class="[showingToolbar ? 'toolbar_offcanvas__expanded' : '', showingBottomBar ? 'bottom_offcanvas__expanded' : '', 'bg-gray-200 sticker_mode_page--container']">
        <div class="d-flex flex-column flex-shrink-0 bg-gray-900 position-fixed end-0 vh-100 side__toolbar--contianer">
            <div class="d-flex justify-content-center py-2">
                <button @click="goBack" class="btn d-flex gap-1 text-gray-900 bg-indigo-300 fw-bold"><i
                    class="bi bi-arrow-left"></i>
                    Back</button>
            </div>
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li class="nav-item">
                    <a href="#" class="nav-link py-3" @click="addRect">
                        <i class="bi bi-layers-half"></i><br />Add Rect
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 1">
                        <i class="bi bi-layers-half"></i><br />Arrange
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 2">
                        <i class="bi bi-cursor-text"></i><br />Text
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 3">
                        <i class="bi bi-palette"></i><br />Background
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 4">
                        <i class="bi bi-heart"></i><br />Clipart
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 5">
                        <i class="bi bi-scissors"></i><br />Shapes
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 6">
                        <i class="bi bi-palette2"></i><br />Material
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 7">
                        <i class="bi bi-chevron-bar-expand"></i><br />Size
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link py-3" @click="showToolbarCanvas('stickerModelTools'); showingTools = 8">
                        <i class="bi bi-boxes"></i><br />Quantity
                    </a>
                </li>
            </ul>
        </div>
        <div class="offcanvas offcanvas-end text-white" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
             id="stickerModelTools" aria-labelledby="stickerModelToolsLabel">
            <ArrangeComponent v-if="showingTools == 1" />
            <TextComponent v-if="showingTools == 2" />
            <BackgroundComponent v-if="showingTools == 3" />
            <ClipartComponent v-if="showingTools == 4" />
            <ShapesComponent v-if="showingTools == 5" />
            <MaterialComponent v-if="showingTools == 6" />
            <SizeComponent v-if="showingTools == 7" />
            <QuantityComponent v-if="showingTools == 8" />
            <button @click="hideToolbarCanvas('stickerModelTools')" class="panel_closer"><img
                :src="require('@/assets/img/sticker-mode/panel_closer.png')" /></button>
        </div>
        <div :class="['offcanvas offcanvas-bottom bottombar_offcanvas']" data-bs-scroll="true" data-bs-backdrop="false"
             tabindex="-1" id="stickerModeBottomPanel" aria-labelledby="stickerModeBottomPanelLabel">
            <div class="row flex-1">
                <div class="col-10 d-flex flex-column">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pre-select-tab" data-bs-toggle="tab"
                                    data-bs-target="#pre-select-tab-pane" type="button" role="tab"
                                    aria-controls="pre-select-tab-pane" aria-selected="true">Pre Selected</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="sticker-img-upload-tab" data-bs-toggle="tab"
                                    data-bs-target="#sticker-img-upload-tab-pane" type="button" role="tab"
                                    aria-controls="sticker-img-upload-tab-pane" aria-selected="false">Sticker Image
                                Upload</button>
                        </li>
                    </ul>
                    <div class="tab-content p-2 flex-1 h-100 d-flex flex-column" id="myTabContent">
                        <div class="tab-pane fade show active flex-1" id="pre-select-tab-pane" role="tabpanel"
                             aria-labelledby="pre-select-tab" tabindex="0">...</div>
                        <div class="tab-pane fade flex-1" id="sticker-img-upload-tab-pane" role="tabpanel"
                             aria-labelledby="sticker-img-upload-tab" tabindex="0">
                            <p class="text-4">Supported formats are PDF, PNG, JPG, SVG, TIF, GIF, BMP, AI, PSD</p>
                            <div class="row g-0 flex-1 p-4">
                                <div v-for="(imagePreview, index) in imagePreviews" :key="imagePreview"
                                     class="col-2 p-1 position-relative">
                                    <img class="sticker_img_upload_preview w-100 h-100 object-fit-cover rounded border"
                                         :src="imagePreview" alt="">
                                    <div class="position-absolute top-0 end-0 p-2">
                                        <button @click="clearImage(index)" class="btn p-1 bg-error text-white preview_img_remove_btn text-3"><i
                                            class="bi bi-trash-fill"></i></button>
                                    </div>
                                </div>
                                <div class="col-2 p-1">
                                    <label for="sticker_image_input"
                                           class="bg-gray-200 d-flex justify-content-center align-items-center cursor-pointer h-100">
                                        <i class="bi bi-plus-lg"></i>
                                    </label>
                                </div>
                            </div>
                            <input ref="fileInput" type="file" class="d-none" id="sticker_image_input" @change="previewImages" multiple>
                        </div>
                    </div>
                </div>
                <div class="col-2 border-start-1">

                </div>
            </div>


            <div class="bottom_panel">
                <button
                    @click="showingBottomBar ? hideBottombarCanvas('stickerModeBottomPanel') : showBottombarCanvas('stickerModeBottomPanel')"><img
                    :src="require('@/assets/img/sticker-mode/panel_down.png')" /></button>
            </div>
        </div>

        <div class="test_div" ref="artboard" id="artboard">
            <svg ref="svgElement" class="user-select-none">
                <defs>
                    <pattern id="backgroundPattern" patternUnits="userSpaceOnUse" x="0" y="0" :width="patternSize" :height="patternSize">
                        <image :href="selectedImage" :width="patternSize" :height="patternSize" />
                    </pattern>
                </defs>
                <g id="container" transform="matrix(1 0 0 1 0 0)">
                    <path :d="svgViewPath" style="stroke: #cccccc;stroke-width:1;fill-opacity:0.2 "></path>
                </g>
                <g id="selectLayerGroup" transform="matrix(1 0 0 1 0 0)"></g>
            </svg>
            <div style="position: absolute; right: 20%; bottom: 20px; padding: 10px; display: flex; gap: 10px">
                <a role="button" style="display: inline-block" @click="updateBackgroundImage('https://d6ce0no7ktiq.cloudfront.net/images/web/editor/prismatic_bg.png')">
                    <img src="https://d6ce0no7ktiq.cloudfront.net/images/web/editor/prismatic_bg.png" alt="prismatic" style="width: 80px; height: 80px" />
                </a>
                <a role="button" style="display: inline-block" @click="updateBackgroundImage('https://d6ce0no7ktiq.cloudfront.net/images/web/editor/holographic_bg.png')">
                    <img src="https://d6ce0no7ktiq.cloudfront.net/images/web/editor/holographic_bg.png" alt="holographic" style="width: 80px; height: 80px" />
                </a>
                <button @click="addImage">Add Image</button>
            </div>
        </div>
    </div>
</template>
<script src="./StickerJS.js"></script>
<style src="./StickerJS.css" scoped></style>
