<template>
  <div class="bg-custom-pink" id="checkout_page">
    <section class="top_bar d-flex justify-content-between">
      <div>
        <img
          class="landing_page--logo"
          :src="require('@/assets/img/utils/logo.png')"
          alt=""
        />
      </div>
      <div>
        <div class="text-4"><i class="fa-solid fa-phone"></i> (406) 555-0120</div>
        <div class="text-4">
          <i class="fa-solid fa-envelope"></i> printpeelable@test.com
        </div>
      </div>
    </section>

    <section class="container mb-5">
      <div class="d-flex align-items-center gap-2 mb-3">
        <router-link :to="{ name: 'sticker.mode' }" class=""><i class="bi bi-arrow-left"></i></router-link>
        <h6 class="mb-0">Checkout Page</h6>
      </div>
      <div class="row w-100 g-0">
        <div class="col-12 col-md-9 p-2">
          <h6>Order Overview</h6>
          <div class="d-flex bg-white p-3 rounded mb-3 gap-3 w-100 overflow-x-auto text-nowrap">
            <div class="overview_img">
              <img class="w-100 border rounded" src="/img/checkout/april.png" alt="" />
            </div>
            <div class="row flex-nowrap flex-1">
              <div class="col-3">
                <p class="text-4 text-gray-600 mb-2">Description</p>
                <h6 class="text-5 mb-0 mb-2">Die-Cut Sticker</h6>
                <p class="text-4 text-gray-800 mb-2">Contour Shape</p>
                <p class="text-4 text-gray-800 mb-2">Vinyl / Glossy</p>
                <p class="text-4 text-gray-800 mb-2">1” x 2”</p>
              </div>
              <div class="col-3">
                <p class="text-4 text-gray-600 mb-2">Quantity</p>
                <select class="form-select" aria-label="Default select example">
                  <option value="1">111</option>
                  <option value="2">222</option>
                  <option value="3">333</option>
                </select>
              </div>
              <div class="col-3">
                <p class="text-4 text-gray-600 mb-2">Price/piece</p>
                <p class="text-gray-900 fw-semibold">$0.45</p>
              </div>
              <div class="col-3">
                <p class="text-4 text-gray-600 mb-2">Total Amount</p>
                <p class="text-gray-900 fw-semibold">0.45</p>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between">
              <button class="btn order_overview_btn bg-gray-900 text-white">
                <i class="bi bi-pencil"></i>
              </button>
              <div class="d-flex flex-column">
                <button class="btn order_overview_btn bg-gray-900 text-white mb-1">
                  <i class="bi bi-copy"></i>
                </button>
                <button class="btn order_overview_btn bg-danger text-white">
                  <i class="bi bi-trash3"></i>
                </button>
              </div>
            </div>
          </div>

          <hr class="overview_hr">

          <h6>Billing Address</h6>
          <div class="bg-white p-3 rounded mb-5">
            <div class="row row-cols-2">
              <div class="mb-3">
                <label class="form-label text-gray-900">First Name</label>
                <input type="text" class="form-control" placeholder="First Name" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" />
              </div>
            </div>
            <div class="row row-cols-2">
              <div class="mb-3">
                <label class="form-label text-gray-900">Email</label>
                <input type="email" class="form-control" placeholder="Email" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">Phone</label>
                <input type="text" class="form-control" placeholder="Phone" />
              </div>
            </div>
            <div class="row row-cols-1">
              <div class="mb-3">
                <label class="form-label text-gray-900">Address</label>
                <input type="text" class="form-control" placeholder="Address" />
              </div>
            </div>
            <div class="row row-cols-4">
              <div class="mb-3">
                <label class="form-label text-gray-900">Zip Code</label>
                <input type="text" class="form-control" placeholder="Zip Code" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">City</label>
                <input type="text" class="form-control" placeholder="City" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">State</label>
                <select class="form-select">
                  <option selected>State</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">Country</label>
                <select class="form-select">
                  <option selected>Country</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row row-cols-1">
              <div class="mb-3">
                <label class="form-label text-gray-900">Order Reference</label>
                <input type="text" class="form-control" placeholder="Order Reference" />
              </div>
            </div>
            <div class="row row-cols-1">
              <div class="mb-3">
                <label class="form-label text-gray-900">Order Instruction</label>
                <textarea
                  type="text"
                  class="form-control"
                  placeholder="Order Instruction"
                ></textarea>
              </div>
            </div>
          </div>

          <h6>Shipping Address</h6>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="same_as_billings"
            />
            <label class="form-check-label text-gray-900" for="same_as_billings">
              Same as billing address
            </label>
          </div>
          <div class="bg-white p-3 rounded">
            <div class="row row-cols-2">
              <div class="mb-3">
                <label class="form-label text-gray-900">First Name</label>
                <input type="text" class="form-control" placeholder="First Name" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" />
              </div>
            </div>
            <div class="row row-cols-2">
              <div class="mb-3">
                <label class="form-label text-gray-900">Email</label>
                <input type="email" class="form-control" placeholder="Email" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">Phone</label>
                <input type="text" class="form-control" placeholder="Phone" />
              </div>
            </div>
            <div class="row row-cols-1">
              <div class="mb-3">
                <label class="form-label text-gray-900">Address</label>
                <input type="text" class="form-control" placeholder="Address" />
              </div>
            </div>
            <div class="row row-cols-4">
              <div class="mb-3">
                <label class="form-label text-gray-900">Zip Code</label>
                <input type="text" class="form-control" placeholder="Zip Code" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">City</label>
                <input type="text" class="form-control" placeholder="City" />
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">State</label>
                <select class="form-select">
                  <option selected>State</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label text-gray-900">Country</label>
                <select class="form-select">
                  <option selected>Country</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3 p-2">
          <div class="mb-3">
            <h6>Delivery Information</h6>
            <div class="bg-white rounded overflow-hidden">
              <div
                :class="[
                  shippingMethod == 'free' ? 'selected_shipping_method' : '',
                  'px-3',
                ]"
              >
                <div class="form-check mb-0">
                  <input
                    v-model="shippingMethod"
                    value="free"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label
                    class="form-check-label d-flex justify-content-between"
                    for="flexRadioDefault2"
                  >
                    <p>Free Apr 29-30</p>
                    <p>$0</p>
                  </label>
                </div>
              </div>
              <div
                :class="[
                  shippingMethod == 'express' ? 'selected_shipping_method' : '',
                  'px-3',
                ]"
              >
                <div class="form-check mb-0">
                  <input
                    v-model="shippingMethod"
                    value="express"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    class="form-check-label d-flex justify-content-between"
                    for="flexRadioDefault1"
                  >
                    <p>Express Delivery Apr 29-30</p>
                    <p>$20</p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <h6>Summary & Payment</h6>
          <div class="bg-white px-3 py-3 rounded overflow-hidden">
            <div class="row row-cols-2 mb-3 text-gray-600 text-3">
              <div class="col">Amount</div>
              <div class="col">$28</div>
              <div class="col">Shipping</div>
              <div class="col">$0</div>

              <div class="col-12">
                <hr class="amount_hr">
              </div>

              <div class="col text-4 fw-semibold text-gray-900">Total Amount</div>
              <div class="col text-4 fw-semibold text-gray-900">$28</div>
            </div>
            <p class="text-3 mb-3">
              By Continuing I accept the <a href="">Terms and Conditions</a> and also
              <a href="">Privacy policies</a>
            </p>
            <div class="card overflow-hidden">
              <ul
                class="nav nav-tabs bg-white border-bottom-0"
                id="paymentMethodTab"
                role="tablist"
              >
                <li class="nav-item flex-1" role="presentation">
                  <button
                    class="nav-link text-gray-700 w-100 border-top-right-radius-0 active"
                    id="credit-card-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#credit-card-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="credit-card-tab-pane"
                    aria-selected="true"
                  >
                    <i class="bi bi-credit-card-fill"></i> Credit Card
                  </button>
                </li>
                <li class="nav-item flex-1" role="presentation">
                  <button
                    class="nav-link text-gray-700 w-100 border-top-left-radius-0"
                    id="paypal-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#paypal-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="paypal-tab-pane"
                    aria-selected="false"
                  >
                    <i class="bi bi-paypal"></i> Paypal
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="paymentMethodTabContent">
                <div
                  class="tab-pane fade show active"
                  id="credit-card-tab-pane"
                  role="tabpanel"
                  aria-labelledby="credit-card-tab"
                  tabindex="0"
                >
                  <div class="px-1 my-3">
                    <p>Supported Cards</p>
                    <div class="d-flex align-items-center gap-1">
                      <img
                        class="supported_card_img"
                        :src="'img/checkout/visa.png'"
                        alt=""
                      />
                      <img
                        class="supported_card_img"
                        :src="'img/checkout/american_express.png'"
                        alt=""
                      />
                      <img
                        class="supported_card_img"
                        :src="'img/checkout/mastercard.png'"
                        alt=""
                      />
                      <p>+2</p>
                    </div>
                  </div>

                  <div class="row g-0">
                    <div class="mb-3 col-12 px-1">
                      <label class="form-label text-gray-900">Card Number</label>
                      <input type="text" class="form-control" placeholder="Card Number" />
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="mb-3 col-6 px-1">
                      <label class="form-label text-gray-900">Expiry Date</label>
                      <input type="text" class="form-control" placeholder="MM/YY" />
                    </div>
                    <div class="mb-3 col-6 px-1">
                      <label class="form-label text-gray-900">CVC</label>
                      <input type="password" class="form-control" placeholder="***" />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="paypal-tab-pane"
                  role="tabpanel"
                  aria-labelledby="paypal-tab"
                  tabindex="0"
                >
                  <div class="px-1 my-3">
                    <p>Supported Cards</p>
                    <div class="d-flex align-items-center gap-1">
                      <img
                        class="supported_card_img"
                        :src="'img/checkout/visa.png'"
                        alt=""
                      />
                      <img
                        class="supported_card_img"
                        :src="'img/checkout/american_express.png'"
                        alt=""
                      />
                      <img
                        class="supported_card_img"
                        :src="'img/checkout/mastercard.png'"
                        alt=""
                      />
                      <p>+2</p>
                    </div>
                  </div>

                  <div class="row g-0">
                    <div class="mb-3 col-12 px-1">
                      <label class="form-label text-gray-900">Card Number</label>
                      <input type="text" class="form-control" placeholder="Card Number" />
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="mb-3 col-6 px-1">
                      <label class="form-label text-gray-900">Expiry Date</label>
                      <input type="text" class="form-control" placeholder="MM/YY" />
                    </div>
                    <div class="mb-3 col-6 px-1">
                      <label class="form-label text-gray-900">CVV</label>
                      <input type="password" class="form-control" placeholder="***" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label text-gray-900 mb-0">Coupon Code</label>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Coupon Code" />
                <span class="input-group-text bg-gray-900 text-gray-100">Apply Code</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label text-gray-900 mb-0">Gift Voucher</label>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Gift Voucher" />
                <span class="input-group-text bg-gray-900 text-gray-100">Apply Code</span>
              </div>
            </div>

            <button class="btn btn-indigo-500 w-100">Confirm Order</button>
          </div>
        </div>
      </div>
    </section>

    <section
      class="landing_footer bg-indigo-300 d-flex flex-column flex-lg-row justify-content-between mt-3 mt-lg-0"
    >
      <div class="flex-1 d-flex justify-content-center justify-content-lg-start">
        <img
          class="landing_page--logo"
          :src="require('@/assets/img/utils/logo.png')"
          alt=""
        />
      </div>
      <div
        class="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-4 flex-1 my-3 my-lg-0 text-no-wrap"
      >
        <router-link
          :to="{ name: 'selection' }"
          class="text-decoration-none text-gray-900"
          >Contact</router-link
        >
        <router-link
          :to="{ name: 'selection' }"
          class="text-decoration-none text-gray-900"
          >About Us</router-link
        >
        <router-link
          :to="{ name: 'selection' }"
          class="text-decoration-none text-gray-900"
          >Custom Sticker</router-link
        >
        <router-link
          :to="{ name: 'selection' }"
          class="text-decoration-none text-gray-900"
          >Blogs</router-link
        >
        <router-link
          :to="{ name: 'selection' }"
          class="text-decoration-none text-gray-900"
          >Materials</router-link
        >
      </div>
      <div
        class="flex-1 d-flex justify-content-center justify-content-lg-end align-items-center gap-3"
      >
        <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"
          ><i class="fa-brands fa-facebook-f"></i
        ></router-link>
        <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"
          ><i class="fa-brands fa-instagram"></i
        ></router-link>
        <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"
          ><i class="fa-brands fa-youtube"></i
        ></router-link>
        <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"
          ><i class="fa-brands fa-x-twitter"></i
        ></router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const shippingMethod = ref("free");
    return {
      shippingMethod,
    };
  },
};
</script>

<style scoped>
.landing_page--logo {
  width: 140px;
}

.landing_page_title {
  font-weight: bold;
  font-size: 52px;
}

.landing_footer {
  padding: 10px 36px;
}

.landing_page__footer--social {
  width: 40px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #212529;
  color: #f8f9fa;
  border-radius: 50%;
}

@media screen and (max-width: 567px) {
  .landing_page--logo {
    width: 80px;
  }
}

#checkout_page .overview_img {
  width: 200px;
  min-width: 120px;
}

.selected_shipping_method {
  background-color: #e0cffc;
}

#paymentMethodTab.nav-tabs .nav-link.active,
#paymentMethodTab.nav-tabs .nav-item.show .nav-link {
  color: #212529 !important;
  background-color: #a370f7 !important;
}

.supported_card_img {
  width: 30px;
  height: 20px;
  object-fit: cover;
}
.order_overview_btn {
  width: 20px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
.overview_hr {
  border: 1px solid #212529;
  opacity: 1;
  margin-top: 52px;
}
.amount_hr {
  border: 1px solid #DEE2E6;
  opacity: 1;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
