<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Background</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3">Change the background color</p>
        <div class="mt-3">
            <p class="mb-1 text-gray-100">Default Color</p>
            <div class="color_container mb-3">
                <button class="btn color_choose_btn" @click="() => handleColorChange(color)" v-for="(color, index) in defaultColors" :key="index" :style="{background: color}"></button>
            </div>
            <p class="mb-1 text-gray-100">Custom Color</p>
            <v-color-picker width="100%" hide-inputs v-model="backgroundColor" @update:modelValue="handleColorChange"></v-color-picker>
        </div>
    </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
    emits: ['bgColorChange'],
    props: {},
    setup(props, {emit}) {
        const store = useStore();
        const defaultColors = computed(() => store.getters["stickerPaper/getBackgroundColors"]);
        const backgroundColor = ref(defaultColors.value[0]);

        const handleColorChange = (color) => {
            backgroundColor.value = color;
            emit('bgColorChange', color);
            //props.updateBackgroundColor(color);
        };
        return {
            handleColorChange,
            backgroundColor,
            defaultColors
        }
    }
}
</script>
