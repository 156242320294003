<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Sticker Size</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3 mb-3">Change the Sticker size or select a predefined size</p>
        <div class="row g-0" id="sizes_container">
            <div v-for="size in sizes" :key="size.id" class="col-6 col-lg-12 px-1">
                <button @click="selectPredefinedSize(size)"
                    :class="[selectedSize.id == size.id ? 'active_selection' : '', 'selection_btn bg-gray-900 d-flex align-items-center gap-2 w-100 p-2 rounded mb-2']">
                    <div class="sticker_size__image--container">
                        <img class="sticker_size--img" :src="size.thumbnail" alt="">
                    </div>
                    <p>{{ size.label }}</p>
                </button>
            </div>
            <div class="col-6 col-lg-12 px-1">
                <button @click="selectPredefinedSize({ id: 'custom' })"
                    :class="[selectedSize.id == 'custom' ? 'active_selection' : '', 'selection_btn bg-gray-900 d-flex align-items-center gap-2 w-100 p-2 rounded mb-2']">
                    <div class="sticker_size__image--container">
                    </div>
                    <p>Custom</p>
                </button>
                <div v-if="selectedSize.id == 'custom'" class="input-group mb-3">
                    <input v-model="customWidth" @input="changeCustomSize" type="number" class="form-control bg-gray-900 text-white"
                        placeholder="Width" min="0">
                    <span @click="clearCustomSizes" class="input-group-text p-2 text-white bg-gray-700 cursor-pointer border-0"><i class="bi bi-x"></i></span>
                    <input v-model="customHeight" @input="changeCustomSize" type="number" class="form-control bg-gray-900 text-white"
                        placeholder="Height" min="0">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import {ref} from 'vue';
import { useStore } from 'vuex';
export default {
    setup() {
        const store = useStore();
        const customWidth = ref(0);
        const customHeight = ref(0);

        const sizes = computed(() => store.getters['selection/getSizes']);
        const selectedSize = computed(() => store.getters['selection/getSelectedSize']);

        const clearCustomSizes = () => {
            customHeight.value = 0;
            customWidth.value = 0;
        };

        const changeCustomSize = async () => {
            if (customHeight.value > 0 && customWidth.value > 0) {
                const body = {
                    width: customWidth.value,
                    height: customHeight.value
                };
                await store.dispatch('selection/handleCustomSize', body);
            }
        };

        const selectPredefinedSize = async (size) => {
            await store.dispatch('selection/setSelectedSize', size)
        };

        return {
            sizes,
            customWidth,
            customHeight,
            clearCustomSizes,
            changeCustomSize,
            selectPredefinedSize,
            selectedSize
        }
    }
}
</script>

<style scoped>
.active_selection {
    background-color: #A370F7 !important;
    color: #212529;
}
.form-control {
    box-shadow: none !important;
    border-color: transparent !important;
}
</style>
