<template>
    <div :class="['offcanvas offcanvas-bottom bottombar_offcanvas']" data-bs-scroll="true" data-bs-backdrop="false"
        tabindex="-1" id="stickerModeBottomPanel" aria-labelledby="stickerModeBottomPanelLabel">
        <div class="row g-0 flex-1 h-100">
            <div class="col-12 col-md-9 d-flex flex-column pe-0 bottombar_left_side_container text-nowrap">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button @click="showBottombarCanvas('stickerModeBottomPanel')" class="nav-link active"
                            id="pre-select-tab" data-bs-toggle="tab" data-bs-target="#pre-select-tab-pane" type="button"
                            role="tab" aria-controls="pre-select-tab-pane" aria-selected="true">Pre
                            Selected</button>
                    </li>
                    <li v-if="showingTab == 'sticker'" class="nav-item" role="presentation">
                        <button @click="showBottombarCanvas('stickerModeBottomPanel')" class="nav-link"
                            id="sticker-img-upload-tab" data-bs-toggle="tab"
                            data-bs-target="#sticker-img-upload-tab-pane" type="button" role="tab"
                            aria-controls="sticker-img-upload-tab-pane" aria-selected="false">Sticker Image
                            Upload</button>
                    </li>
                    <li v-if="showingTab == 'backpaper'" class="nav-item" role="presentation">
                        <button @click="showBottombarCanvas('stickerModeBottomPanel')" class="nav-link"
                            id="backpaper-img-upload-tab" data-bs-toggle="tab"
                            data-bs-target="#backpaper-img-upload-tab-pane" type="button" role="tab"
                            aria-controls="backpaper-img-upload-tab-pane" aria-selected="false">Sticker Backpaper
                            Upload</button>
                    </li>
                </ul>
                <div class="tab-content p-2 flex-1 overflow-hidden d-flex flex-column" id="myTabContent">
                    <div class="tab-pane fade show active flex-1 h-100" id="pre-select-tab-pane" role="tabpanel"
                        aria-labelledby="pre-select-tab" tabindex="0">
                        <div class="d-flex align-items-stretch gap-3 overflow-x-auto">
                            <div class="text-start d-flex flex-column">
                                <p class="text-3 fw-bold">Sticker Shapes</p>
                                <button
                                    class="btn border ps-1 overflow-hidden d-flex align-items-center justify-content-start h-100">
                                    <img class="mh-100 bottom_bar_selected_img"
                                        :src="preSelected?.selectedShape?.thumbnail" alt="">
                                    <p>{{ preSelected?.selectedShape?.name }}</p>
                                </button>
                            </div>
                            <div class="text-start d-flex flex-column">
                                <p class="text-3 fw-bold">Sticker Materials</p>
                                <button
                                    class="btn border ps-1 overflow-hidden d-flex align-items-center justify-content-start h-100">
                                    <img class="mh-100 bottom_bar_selected_img"
                                        :src="preSelected?.selectedMaterial?.thumbnail" alt="">
                                    <p>{{ preSelected?.selectedMaterial?.name }}</p>
                                </button>
                            </div>
                            <div class="text-start d-flex flex-column">
                                <p class="text-3 fw-bold">Sticker Coating</p>
                                <button
                                    class="btn border ps-1 overflow-hidden d-flex align-items-center justify-content-start h-100">
                                    <img class="mh-100 bottom_bar_selected_img"
                                        :src="preSelected?.selectedCoating?.thumbnail" alt="">
                                    <p>{{ preSelected?.selectedCoating?.name }}</p>
                                </button>
                            </div>
                            <div class="text-start d-flex flex-column">
                                <p class="text-3 fw-bold">Sticker Sizes</p>
                                <button
                                    class="btn border ps-1 overflow-hidden d-flex align-items-center justify-content-start h-100">
                                    <img class="mh-100 bottom_bar_selected_img"
                                        :src="preSelected?.selectedSize?.thumbnail" alt="">
                                    <p>{{ preSelected?.selectedSize?.label }}</p>
                                </button>
                            </div>
                            <div class="text-start d-flex flex-column">
                                <p class="text-3 fw-bold">Sticker Quantiry</p>
                                <button
                                    class="btn border ps-1 overflow-hidden d-flex align-items-center justify-content-start h-100">
                                    <p>{{ preSelected?.selectedQuantity?.quantity }} pcs</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="showingTab == 'sticker'" class="tab-pane fade flex-1 overflow-hidden"
                        id="sticker-img-upload-tab-pane" role="tabpanel" aria-labelledby="sticker-img-upload-tab"
                        tabindex="0">
                        <p class="text-4">Supported formats are PDF, PNG, JPG, SVG, TIF, GIF, BMP, AI, PSD</p>
                        <div class="row row-cols-auto g-0 p-2 overflow-hidden uploaded_images_container">
                            <div v-for="imagePreview in imagePreviews" :key="imagePreview.id"
                                class="col p-1 position-relative h-100">
                                <img class="sticker_img_upload_preview object-fit-cover rounded border"
                                    :src="imagePreview.img" alt="">
<!--                                <div class="position-absolute top-0 end-0 p-2">
                                    <button @click="clearImage(imagePreview)"
                                        class="btn p-1 bg-error text-white preview_img_remove_btn text-3"><i
                                            class="bi bi-trash-fill"></i></button>
                                </div>-->
                            </div>
                            <div class="col p-1 position-relative h-100" v-if="isFrontPaperUpload">
                                <img class="sticker_img_upload_preview object-fit-cover rounded border" :src="require('@/assets/img/image-uploader.png')" style="opacity: .3;" alt="">
                                <div class="position-relative" style="top: -50%;transform: translateY(-50%);width: 80%;margin: 0 auto;">
                                    <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 8px">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%;background-size: 100%;"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-1 position-relative h-100" v-if="isBackPaperUpload">
                                <img class="sticker_img_upload_preview object-fit-cover rounded border" :src="require('@/assets/img/image-uploader.png')" style="opacity: .3;" alt="">
                                <div class="position-relative" style="top: -50%;transform: translateY(-50%);width: 80%;margin: 0 auto;">
                                    <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 8px">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%;background-size: 100%;"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 p-1">
                                <label for="sticker_image_input"
                                    class="bg-gray-200 d-flex justify-content-center align-items-center cursor-pointer h-100">
                                    <i class="bi bi-plus-lg"></i>
                                </label>
                            </div>
                        </div>
                        <input ref="fileInput" type="text" class="d-none" id="sticker_image_input" @click="addImage"
                            multiple>
                    </div>
                    <div v-if="showingTab == 'backpaper'" class="tab-pane fade flex-1 overflow-hidden"
                        id="backpaper-img-upload-tab-pane" role="tabpanel" aria-labelledby="backpaper-img-upload-tab"
                        tabindex="0">
                        <p class="text-4">Supported formats are PDF, PNG, JPG, SVG, TIF, GIF, BMP, AI, PSD</p>
                        <div class="row row-cols-auto g-0 p-2 overflow-hidden uploaded_images_container">
                            <div v-for="backpaperPreview in imagePreviewsBackpaper" :key="backpaperPreview.id"
                                class="col p-1 position-relative h-100">
                                <img class="sticker_img_upload_preview object-fit-cover rounded border"
                                    :src="backpaperPreview.img" alt="">
<!--                                <div class="position-absolute top-0 end-0 p-2">
                                    <button @click="clearBackpaper(backpaperPreview)"
                                        class="btn p-1 bg-error text-white preview_img_remove_btn text-3"><i
                                            class="bi bi-trash-fill"></i></button>
                                </div>-->
                            </div>
                            <div class="col-1 p-1">
                                <label for="backpaper_image_input"
                                    class="bg-gray-200 d-flex justify-content-center align-items-center cursor-pointer h-100">
                                    <i class="bi bi-plus-lg"></i>
                                </label>
                            </div>
                        </div>
                        <input ref="backPaperInput" type="text" class="d-none" id="backpaper_image_input"
                            @click="addImage" multiple>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 d-flex justify-content-end justifiy-content-md-start flex-column border-start p-3 pt-1">
                <div class="cart_text_container">
                    <p class="text-4 fw-semibold mb-3 d-none d-md-block">Proceed To Cart</p>
                    <p class="text-4 mb-3 d-none d-md-block">Proceed to cart after editing to your liking</p>
                    <div class="d-flex justify-content-between fw-semibold">
                        <p>Total</p>
                        <p>$50</p>
                    </div>
                </div>
                <button @click="proceedToCheckout" type="button" class="btn btn-indigo-500">Proceed to Cart</button>
            </div>
        </div>
        <div class="bottom_panel">
            <button
                @click="showingBottomBar ? hideBottombarCanvas('stickerModeBottomPanel') : showBottombarCanvas('stickerModeBottomPanel')"><img
                    :src="showingBottomBar ? require('@/assets/img/sticker-mode/panel_down.png') : require('@/assets/img/sticker-mode/panel_up.png')" /></button>
        </div>
    </div>
</template>

<script>
import { closeCanvas, openCanvas } from '@/assets/js/sticker-mode';
import axios from 'axios';
import { watch } from 'vue';
import { ref } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import {useRouter} from "vue-router";

export default {
    props: {
        addImage: Function,
        isFrontPaperUpload: {
            type: Boolean,
            required: true,
        },
        isBackPaperUpload: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const fileInput = null;
        const backPaperInput = null;
        const imagePreviews = computed(() => store.getters['sticker/getImagePreviews']);
        const imagePreviewsBackpaper = computed(() => store.getters['sticker/getImagePreviewsBackpaper']);
        const backpaperPreviews = computed(() => store.getters['sticker/getBackpaperPreviews']);
        const showingTab = computed(() => store.getters['sticker/getShowingTab']);

        const showingBottomBar = computed(() => store.getters['sticker/getShowingBottomBar']);
        const preSelected = ref(JSON.parse(JSON.stringify(store.getters['selection/getPreselected'])));

        const reactiveSelectedShape = computed(() => store.getters['selection/getSelectedShape']);
        const reactiveSelectedMaterial = computed(() => store.getters['selection/getSelectedMaterial']);
        const reactiveSelectedCoating = computed(() => store.getters['selection/getSelectedCoating']);
        const reactiveSelectedSize = computed(() => store.getters['selection/getSelectedSize']);
        const reactiveSelectedQuantity = computed(() => store.getters['selection/getSelectedQuantity']);
        const selectedImage = ref(preSelected.value.selectedMaterial.image);
        const backPaperEdit = computed(() => store.getters["stickerPaper/getBackPaperEdit"]);

        watch(
            [reactiveSelectedShape, reactiveSelectedMaterial, reactiveSelectedCoating, reactiveSelectedSize, reactiveSelectedQuantity],
            ([newSelectedShape, newSelectedMaterial, newSelectedCoating, newSelectedSize, newSelectedQuantity]) => {
                preSelected.value.selectedShape = newSelectedShape;
                preSelected.value.selectedMaterial = newSelectedMaterial;
                preSelected.value.selectedCoating = newSelectedCoating;
                preSelected.value.selectedSize = newSelectedSize;
                preSelected.value.selectedQuantity = newSelectedQuantity;
                selectedImage.value = preSelected.value.selectedMaterial.image;
            }
        );

        watch(
            [backPaperEdit], ([newValue]) => {
                if (newValue) {
                    //console.log(backpaperPreviews.value)
                } else {
                    //console.log(imagePreviews.value)
                }
            }
        );

        const showBottombarCanvas = (id) => {
            if (!showingBottomBar.value) {
                openCanvas(id);
            }
            store.dispatch('sticker/setShowingBottomBar', true);
        }
        const hideBottombarCanvas = (id) => {
            closeCanvas(id);
            showingBottomBar.value = false;
            store.dispatch('sticker/setShowingBottomBar', false);
        }
        const clearImage = async (image) => {
            await store.dispatch('sticker/removeImagePreviews', image.id);
            if (fileInput) {
                fileInput.value = '';
            }
        };
        const addBackpaper = async (e) => {
            let files = Array.from(e.target.files);  // Use e.target.files to get the files from the input element
            if (!files || !files.length) return;

            const form = new FormData();
            form.append('file', files[0]);  // Append the first file from the input

            try {
                const response = await axios.post('http://localhost:3000/api/v1/files/store', form);
                await store.dispatch('sticker/addBackpaperPreviews', {
                    id: response.data.file,
                    img: response.data.file_path
                });
                store.dispatch('sticker/addLayer', {
                    id: response.data.file,
                    type: 'image',
                    img: response.data.file_path
                });
            } catch (error) {
                console.error('Error uploading file:', error);  // Handle any errors
            }
        }
        const clearBackpaper = async (backpaper) => {
            await store.dispatch('sticker/removeBackpaperPreviews', backpaper.id);
            if (backPaperInput) {
                backPaperInput.value = '';
            }
        };

        const proceedToCheckout = () => {
            router.push({ name: 'checkout' });
        }
        return {
            showBottombarCanvas,
            hideBottombarCanvas,
            showingBottomBar,
            preSelected,
            fileInput,
            clearImage,
            imagePreviews,
            imagePreviewsBackpaper,
            showingTab,
            addBackpaper,
            backPaperInput,
            backpaperPreviews,
            clearBackpaper,
            proceedToCheckout
        }
    }
}
</script>

<style scoped>
.bottombar_offcanvas {
    transition: all 0.3s ease-in-out;
    background: #fff !important;
    border: none !important;
}

.bottombar_offcanvas .nav-tabs .nav-link {
    padding-bottom: 0;
    padding-top: 0;
    height: 100%;
    color: #212529 !important;
}

.bottombar_offcanvas .nav-tabs .nav-link:focus,
.bottombar_offcanvas .nav-tabs .nav-link:hover,
.bottombar_offcanvas .nav-tabs .nav-link {
    border: none !important;
}

.bottombar_offcanvas .nav-tabs .nav-link.active {
    color: #6610F2 !important;
    border: none;
}

.bottombar_offcanvas .tab-content>.active {
    display: flex;
    flex-direction: column;
}

.bottom_panel {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translateY(-100%);
}

.bottom_panel button {
    line-height: 0;
}

.bottom_bar_selected_img {
    width: auto;
    height: 40px;
    margin-right: 8px;
}
</style>
