<script scope>
import {ref} from "vue";

export default ({
  setup() {
    let sidebarOpen = ref(true);
    let name = "Yellow";
    let getHello = () => {
      alert("Hello!")
    }
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value
    }
    return {
      name,
      getHello,
      sidebarOpen,
      toggleSidebar
    }
  }
})
</script>

<template>
  <main class="container-fluid p-0">
    <header>
      <h1>Edit Mode</h1>
    </header>
    <section class="editor">
      <article>
        View Edit
        <div class="canvas-parent">
          <canvas id="canvas" width="800" height="600"></canvas>
        </div>
      </article>
      <aside class="sidebar" :class="sidebarOpen ? 'sidebar-open' : ''">
        <div class="d-flex flex-column flex-shrink-0 bg-light" style="width: 4.5rem;">
          <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
            <li class="nav-item">
              <a @click="toggleSidebar" class="nav-link active py-3 border-bottom rounded-0" aria-current="page" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Home" data-bs-original-title="Home">
                <i class="fa-solid fa-upload"></i>
              </a>
            </li>
            <li>
              <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Dashboard" data-bs-original-title="Dashboard">
                <svg class="bi pe-none" width="24" height="24" role="img" aria-label="Dashboard"><use xlink:href="#speedometer2"></use></svg>
              </a>
            </li>
<!--            <li>
              <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Orders" data-bs-original-title="Orders">
                <svg class="bi pe-none" width="24" height="24" role="img" aria-label="Orders"><use xlink:href="#table"></use></svg>
              </a>
            </li>
            <li>
              <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Products" data-bs-original-title="Products">
                <svg class="bi pe-none" width="24" height="24" role="img" aria-label="Products"><use xlink:href="#grid"></use></svg>
              </a>
            </li>
            <li>
              <a href="#" class="nav-link py-3 border-bottom rounded-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Customers" data-bs-original-title="Customers">
                <svg class="bi pe-none" width="24" height="24" role="img" aria-label="Customers"><use xlink:href="#people-circle"></use></svg>
              </a>
            </li>-->
          </ul>
<!--          <div class="dropdown border-top">
            <a href="#" class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://github.com/mdo.png" alt="mdo" width="24" height="24" class="rounded-circle">
            </a>
            <ul class="dropdown-menu text-small shadow" style="">
              <li><a class="dropdown-item" href="#">New project...</a></li>
              <li><a class="dropdown-item" href="#">Settings</a></li>
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Sign out</a></li>
            </ul>
          </div>-->
        </div>
      </aside>
      <article>
        <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Toggle bottom offcanvas</button>
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div>
              Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
            </div>
            <div class="dropdown mt-3">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                Dropdown button
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </section>
  </main>
</template>

<style scoped>
  main {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 60px auto 40px;
    /*grid-template-areas:
      "header header"
      "main nav"
      "footer footer";*/
    position: relative;
    background: white;
    font-size: 1rem;
  }
  header {
    //grid-area: header;
    padding: 10px;
    background-color: #f6faff;
  }
  .editor {
    //grid-area: main;
    display: grid;
    grid-template-columns: auto 300px;
  }
  article {
    padding: 10px;
  }
  .sidebar {
    //grid-area: nav;
    background-color: #454949;
    position: relative;
  }
  .sidebar.sidebar-open {
    //background-color: darkseagreen;
  }
  .sidebar ul {
    list-style: none;
  }
  .sidebar .sidebar-link {
    padding: 10px;
    cursor: pointer;
    color: white;
  }
  .sidebar .sidebar-link:hover {
    background: #e5e9e9;
    color: black;
  }
  .sidebar-toggle {
    position: absolute;
    top: 50%;
    left: -10px;
  }
  footer {
    //grid-area: footer;
    //background-color: yellow;
  }
</style>