<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Sticker Quantity</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3 mb-3">Change the amount of sticker to order</p>
        <div class="row g-0">
            <div v-for="quantity in quantities" :key="quantity.id" class="col-6 col-lg-12 px-1">
                <button @click="handleSelectPredefinedQuantity(quantity)"
                    :class="[selectedQuantity.id == quantity.id ? 'active_selection' : '', 'selection_btn d-flex align-items-center justify-content-between gap-2 bg-gray-900 p-2 rounded mb-2 w-100']">
                    <p>{{ quantity.quantity }} pcs</p>
                    <p>${{ quantity.totalPrice }}</p>
                </button>
            </div>
            <div class="col-6 col-lg-12 px-1">
                <button @click="handleSelectPredefinedQuantity({ id: 'custom' })"
                    :class="[selectedQuantity.id == 'custom' ? 'active_selection' : '', 'selection_btn d-flex align-items-center justify-content-between gap-2 bg-gray-900 p-2 rounded mb-2 w-100']">
                    <p>Custom</p>
                    <p>${{ customPrice || 0 }}</p>
                </button>
                <input v-if="selectedQuantity.id == 'custom'" v-model="customQuantity" @input="inputCustomQuantity"
                    type="number" class="form-control bg-gray-900 text-white text-4" placeholder="Custom Quantity"
                    min="0">
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const customQuantity = ref(0);

        const customPrice = computed(() => store.getters['selection/getCustomPrice']);
        const quantities = computed(() => store.getters['selection/getQuantities']);
        const selectedQuantity = computed(() => store.getters['selection/getSelectedQuantity']);

        const handleSelectPredefinedQuantity = async (quantity) => {
            await store.dispatch('selection/setSelectedQuantity', quantity);
        }

        const inputCustomQuantity = async () => {
            await store.dispatch('selection/handleCustomQuantity', customQuantity.value);
        }

        return {
            quantities,
            selectedQuantity,
            customQuantity,
            inputCustomQuantity,
            handleSelectPredefinedQuantity,
            customPrice
        }
    }
}
</script>

<style scoped>
.active_selection {
    background-color: #A370F7 !important;
    color: #212529;
}
</style>
