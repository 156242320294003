const state = {
    layers: [],
    layersBackpaper: [],
    showingBottomBar: false,
    showingTab: 'sticker',
    imagePreviews: [],
    imagePreviewsBackpaper: [],
    backpaperPreviews: [],
    windowSize: 0,
};

const getters = {
    getLayers: (state) => state.layers,
    getLayersBackpaper: (state) => state.layersBackpaper,
    getShowingBottomBar: (state) => state.showingBottomBar,
    getImagePreviews: (state) => state.imagePreviews,
    getImagePreviewsBackpaper: (state) => state.imagePreviewsBackpaper,
    getBackpaperPreviews: (state) => state.backpaperPreviews,
    getShowingTab: (state) => state.showingTab,
    getWindowSize: (state) => state.windowSize,
};

const actions = {
    addLayer: async({ commit }, layerData) => {
        commit('addLayer', layerData);
    },
    addLayerBackpaper: async({ commit }, layerData) => {
        commit('addLayerBackpaper', layerData);
    },
    updateLayer: async({ commit }, layerData) => {
        commit('updateLayer', layerData)
    },
    updateLayerBackpaper: async({ commit }, layerData) => {
        commit('updateLayerBackpaper', layerData)
    },
    removeLayer: async({ commit }, layerId) => {
        commit('removeLayer', layerId);
    },
    removeLayerBackpaper: async({ commit }, layerId) => {
        commit('removeLayerBackpaper', layerId);
    },
    setLayers: async({ commit }) => {
        commit('setLayers');
    },
    setShowingBottomBar: async({ commit }, showingBottomBar) => {
        commit('setShowingBottomBar', showingBottomBar);
    },
    addImagePreviews: async({ commit }, imageData) => {
        commit('addImagePreviews', imageData);
    },
    addImagePreviewsBackpaper: async({ commit }, imageData) => {
        commit('addImagePreviewsBackpaper', imageData);
    },
    removeImagePreviews: async({ commit }, imageDataId) => {
        commit('removeImagePreviews', imageDataId);
    },
    removeImagePreviewsBackpaper: async({ commit }, imageDataId) => {
        commit('removeImagePreviewsBackpaper', imageDataId);
    },

    addBackpaperPreviews: async({ commit }, backpaperData) => {
        commit('addBackpaperPreviews', backpaperData);
    },
    removeBackpaperPreviews: async({ commit }, backpaperId) => {
        commit('removeBackpaperPreviews', backpaperId);
    },
    setShowingTab: async({ commit }, showingTab) => {
        commit('setShowingTab', showingTab);
    },
    setWindowSize: async({ commit }) => {
        const app = document.getElementById('app');
        const stickerModelTools = document.getElementById('stickerModelTools');

        if (window.innerWidth < 568) {
            app.classList.add('small_device');

            stickerModelTools.classList.add('offcanvas-bottom');
            stickerModelTools.classList.remove('offcanvas-end');
        } else {
            app.classList.remove('small_device');

            stickerModelTools.classList.add('offcanvas-end');
            stickerModelTools.classList.remove('offcanvas-bottom');
        }

        commit('setWindowSize', window.innerWidth);
    },
};

const mutations = {
    addLayer: (state, layerData) => {
        state.layers.push(layerData);
    },
    updateLayer: (state, layerData) => {
        const index = state.layers.findIndex(layer => layer.id === layerData.id);
        if (index > -1) {
            state.layers.splice(index, 1, layerData);
        }
    },
    removeLayer: (state, layerId) => {
        state.layers = state.layers.filter(item => item.id !== layerId);
    },
    addLayerBackpaper: (state, layerData) => {
        state.layersBackpaper.push(layerData);
    },
    updateLayerBackpaper: (state, layerData) => {
        const index = state.layersBackpaper.findIndex(layer => layer.id === layerData.id);
        if (index > -1) {
            state.layersBackpaper.splice(index, 1, layerData);
        }
    },
    removeLayerBackpaper: (state, layerId) => {
        state.layersBackpaper = state.layersBackpaper.filter(item => item.id !== layerId);
    },
    setLayers: (state) => {
        state.layers = [];
    },
    setShowingBottomBar: (state, showingBottomBar) => state.showingBottomBar = showingBottomBar,
    addImagePreviews: (state, imageData) => state.imagePreviews.push(imageData),
    removeImagePreviews: (state, imageDataId) => state.imagePreviews = state.imagePreviews.filter(item => item.id !== imageDataId),
    removeImagePreviewsBackpaper: (state, imageDataId) => state.imagePreviewsBackpaper = state.imagePreviewsBackpaper.filter(item => item.id !== imageDataId),
    addImagePreviewsBackpaper: (state, imageData) => state.imagePreviewsBackpaper.push(imageData),

    addBackpaperPreviews: (state, backpaperData) => state.backpaperPreviews.push(backpaperData),
    removeBackpaperPreviews: (state, backpaperId) => state.backpaperPreviews = state.backpaperPreviews.filter(item => item.id !== backpaperId),

    setShowingTab: (state, showingTab) => state.showingTab = showingTab,
    setWindowSize: (state, windowSize) => state.windowSize = windowSize,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
