import { createRouter, createWebHistory } from 'vue-router';

// Import your components
import Home from '../components/Home/HomeComponent.vue';
import StickerEditor from "@/components/StickerEditor/StickerEditor.vue";
import SelectionComponent from '@/components/Selection/SelectionComponent.vue';
import StickerMode from '@/components/Sticker-mode/StickerMode.vue';
import StickerEngineComponent from "@/components/StickerEngine/StickerEngineComponent.vue";
import StickerJS from "@/components/StickerJS.vue";
import CheckoutComponent from '@/components/Checkout/CheckoutComponent.vue';

const routes = [
  { path: '/', component: Home, meta: { title: 'Home'}, },
  { path: '/selection', name: 'selection', component: SelectionComponent, meta: { title: 'Selection'}, },
  { path: '/sticker-mode', name: 'sticker.mode', component: StickerMode, meta: { title: 'Sticker Mode'}, },
  { path: '/sticker-engine', component: StickerEngineComponent },
  { path: '/sticker-editor', component: StickerEditor },
  { path: '/sticker-js', component: StickerJS },
  { path: '/checkout', name: 'checkout', component: CheckoutComponent },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to) => {
  const baseTitle = ' - Sticker Wiz';
  document.title = (to.meta.title || 'Home') + baseTitle;
});

export default router;
