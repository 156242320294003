<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Arrange</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3">Arrange the layers</p>
        <div class="mt-3">
            <ul class="dnd-container" v-if="!backPaperEdit">
                <li v-for="(item, index) in stickerLayers" :key="index" @dragover="dragOver($event, index)"
                    @drop="drop($event, index)"
                    :class="[draggedIndex === index ? 'dragging' : '', 'dnd-item d-flex align-items-center bg-gray-700 mb-2 p-2 rounded']">
                    <i class="bi bi-grip-vertical text-8 text-gray-900" draggable="true"
                        @dragstart="dragStart($event, index)" @dragend="dragEnd"></i>
                    <div draggable="false" @dragstart="preventDrag"
                        class="h-100 flex-1 d-flex justify-content-center align-itmes-center">
                        <img v-if="item?.type === 'image'" class="mw-100 mh-100" :src="item.img" alt="">
                        <img v-if="item?.type === 'clipart'" class="mw-100 mh-100" :src="item.thumbnail" alt="">
                        <p v-if="item?.type === 'text'" :style="{ color: item.style.color, fontFamily: item.style.font.font, textAlign: item.style.alignment }">{{ item.text }}</p>
                    </div>
                </li>
            </ul>
            <ul class="dnd-container" v-if="backPaperEdit">
                <li v-for="(item, index) in stickerLayersBackpaper" :key="index" @dragover="dragOver($event, index)"
                    @drop="drop($event, index)"
                    :class="[draggedIndex === index ? 'dragging' : '', 'dnd-item d-flex align-items-center bg-gray-700 mb-2 p-2 rounded']">
                    <i class="bi bi-grip-vertical text-8 text-gray-900" draggable="true"
                       @dragstart="dragStart($event, index)" @dragend="dragEnd"></i>
                    <div draggable="false" @dragstart="preventDrag"
                         class="h-100 flex-1 d-flex justify-content-center align-itmes-center">
                        <img v-if="item?.type === 'image'" class="mw-100 mh-100" :src="item.img" alt="">
                        <img v-if="item?.type === 'clipart'" class="mw-100 mh-100" :src="item.thumbnail" alt="">
                        <p v-if="item?.type === 'text'" :style="{ color: item.style.color, fontFamily: item.style.font.font, textAlign: item.style.alignment }">{{ item.text }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from 'vue';
import {useStore} from "vuex";

export default {
    props: {
        rearrangeLayers: Function
    },
    setup(props) {
        const store = useStore();
        const stickerLayers = computed(() => store.getters['sticker/getLayers']);
        const stickerLayersBackpaper = computed(() => store.getters['sticker/getLayersBackpaper']);
        const backPaperEdit = computed(() => store.getters["stickerPaper/getBackPaperEdit"]);
        const draggedIndex = ref(null);
        const previousIndex = ref(null);
        const currentIndex = ref(null);

        watch(
            [backPaperEdit],
            ([newValue]) => {
                if (newValue) {
                    //stickerLayers.value = store.getters['sticker/getLayersBackpaper'];
                    //console.log(stickerLayersBackpaper.value);
                } else {
                    //console.log(stickerLayers.value);
                    //stickerLayers.value = store.getters['sticker/getLayers'];
                }
            }
        );

        const dragStart = (event, index) => {
            previousIndex.value = index;
            currentIndex.value = null;
            draggedIndex.value = index;
            event.target.classList.add('dragging');
        };

        const dragEnd = (event) => {
            event.target.classList.remove('dragging');
            draggedIndex.value = null;
        };

        const dragOver = (event, index) => {
            event.preventDefault();
            if (draggedIndex.value === index) return;

            currentIndex.value = index;
            const draggedItem = stickerLayers.value[draggedIndex.value];
            stickerLayers.value.splice(draggedIndex.value, 1);
            stickerLayers.value.splice(index, 0, draggedItem);
            draggedIndex.value = index;
        };

        const drop = (event, index) => {
            event.preventDefault();
            const draggedItem = stickerLayers.value.splice(draggedIndex.value, 1)[0];
            stickerLayers.value.splice(index, 0, draggedItem);
            props.rearrangeLayers(backPaperEdit.value, previousIndex.value, currentIndex.value);
            previousIndex.value = null;
            currentIndex.value = null;
            draggedIndex.value = null;
        };

        const preventDrag = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return {
            draggedIndex,
            dragStart,
            dragEnd,
            dragOver,
            drop,
            preventDrag,
            stickerLayers,
            backPaperEdit,
            stickerLayersBackpaper
        };
    },
};
</script>

<style scoped>
.dnd-container {
    list-style: none;
    padding: 0;
}

.dragging {
    opacity: 0.5;
}

.dnd-item {
    height: 60px;
}

.bi.bi-grip-vertical {
    cursor: grab;
}
</style>
