<template>
    <div class="bg-custom-pink">
        <section class="top_bar d-flex justify-content-between">
            <div>
                <h4 class="text-6">Customize Sticker</h4>
            </div>
            <div>
                <div class="text-4"><i class="fa-solid fa-phone"></i> (406) 555-0120</div>
                <div class="text-4"><i class="fa-solid fa-envelope"></i> printpeelable@test.com</div>
            </div>
        </section>
        <section class="container bg-white p-2 mb-3 selection__contianer">
            <div class="row g-0">
                <div class="col-12 col-lg-3 px-1 selection_box">
                    <h6>Sticker Type</h6>
                    <div class="flex-1 bg-gray-200 rounded h-100 customized_scrolled">
                        <div class="p-3 pb-1">
                            <button v-for="product in products" :key="product.id"
                                @click="handleSelectStickerType(product.id)" active_selection
                                :class="[selectedStickerType == product.id ? 'active_selection' : '', 'selection_btn d-flex align-items-center align-items-center gap-2 bg-white w-100 p-2 rounded mb-2']">
                                <img class="sticker_type--img border p-1" :src="product.thumbnail" alt="">
                                <p class="m-0">{{ product.name }}</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5 px-1 selection_box fs-12">
                    <h6>Sticker Shape</h6>
                    <div class="bg-gray-200 pt-1 ps-1 pe-1 rounded pb-1 row g-0">
                        <div v-for="(shape, index) in shapes" :key="index"
                            :class="[shapes.length == 1 ? 'col-lg-12' : '', shapes.length == 2 ? 'col-lg-6' : '', shapes.length == 3 ? 'col-lg-4' : '', shapes.length >= 4 ? 'col-lg-3' : '', 'col-6 col-md-4 p-1']">
                            <button @click="handleSelectStickerShape(shape)"
                                :class="[selectedStickerShape == shape.id ? 'active_selection' : '', 'selection_btn bg-white p-2 rounded w-100']">
                                <div class="px-4">
                                    <img class="shape_img" :src="shape.thumbnail" alt="">
                                </div>
                                <div class="scrolling-container">
                                    <p class="m-0 scrolling-text">{{ shape.name }}</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <h6 class="mt-3">Sticker Materials</h6>
                    <div class="flex-1 bg-gray-200 rounded customized_scrolled">
                        <div class="pt-1 ps-1 pe-1 rounded pb-1 row g-0">
                            <div v-for="material in materials" :key="material.id" class="col-6 col-md-4 col-lg-3 p-1">
                                <button @click="handleSelectStickerMaterial(material)"
                                    :class="[selectedStickerMaterial == material.id ? 'active_selection' : '', 'selection_btn bg-white p-2 rounded w-100']">
                                    <div class="">
                                        <img class="material_img" :src="material.thumbnail" alt="">
                                    </div>
                                    <div class="scrolling-container">
                                        <p class="m-0 scrolling-text">{{ material.name }}</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-2 px-1 selection_box fs-12">
                    <h6>Sticker Coating</h6>
                    <div class="bg-gray-200 pt-1 ps-1 pe-1 rounded pb-1 row g-0">
                        <div v-for="coating in coatings" :key="coating.id" class="col-6 p-1">
                            <button @click="handleSelectStickerCoating(coating)"
                                :class="[selectedStickerCoating == coating.id ? 'active_selection' : '', 'selection_btn bg-white p-2 rounded w-100']">
                                <div class="">
                                    <img class="coating_img" :src="coating.thumbnail" alt="">
                                </div>
                                <div class="scrolling-container">
                                    <p class="m-0 scrolling-text">{{ coating.name }}</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <h6 class="mt-3">Sticker Sizes</h6>
                    <div class="flex-1 bg-gray-200 rounded customized_scrolled" id="sizes_container">
                        <div class="pt-3 ps-1 pe-1 rounded pb-1">
                            <div class="row g-0">
                                <div v-for="size in sizes" :key="size.id" class="col-6 col-lg-12 px-1">
                                    <button @click="handleSelectPredefinedSize(size)"
                                        :class="[selectedSize == size.id ? 'active_selection' : '', 'selection_btn d-flex align-items-center gap-2 bg-white w-100 p-2 rounded mb-2']">
                                        <div class="sticker_size__image--container">
                                            <img class="sticker_size--img" :src="size.thumbnail" alt="">
                                        </div>
                                        <p>{{ size.label }}</p>
                                    </button>
                                </div>
                                <div class="col-6 col-lg-12 px-1">
                                    <button @click="handleSelectPredefinedSize({ id: 'custom' })"
                                        :class="[selectedSize == 'custom' ? 'active_selection' : '', 'selection_btn d-flex align-items-center gap-2 bg-white w-100 p-2 rounded mb-2']">
                                        <div class="sticker_size__image--container">
                                        </div>
                                        <p>Custom</p>
                                    </button>
                                    <div v-if="selectedSize == 'custom'" class="input-group mb-3">
                                        <input v-model="customWidth" @input="handleCustomSize" type="number"
                                            class="form-control" placeholder="Width" min="0">
                                        <span @click="clearCustomSizes" class="input-group-text p-0"><i
                                                class="bi bi-x"></i></span>
                                        <input v-model="customHeight" @input="handleCustomSize" type="number"
                                            class="form-control" placeholder="Height" min="0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-2 px-1 selection_box fs-12 position-relative">
                    <h6>Quantity</h6>
                    <div class="flex-1 bg-gray-200 p-1 customized_scrolled rounded pt-3">
                        <div class="row g-0">
                            <div v-for="quantity in quantities" :key="quantity.id" class="col-6 col-lg-12 px-1">
                                <button @click="handleSelectPredefinedQuantity(quantity)"
                                    :class="[selectedQuantity == quantity.id ? 'active_selection' : '', 'selection_btn d-flex align-items-center justify-content-between gap-2 bg-white p-2 rounded mb-2 w-100']">
                                    <p>{{ quantity.quantity }} pcs</p>
                                    <p>${{ quantity.totalPrice }}</p>
                                </button>
                            </div>
                            <div class="col-6 col-lg-12 px-1">
                                <button @click="handleSelectPredefinedQuantity({ id: 'custom' })"
                                    :class="[selectedQuantity == 'custom' ? 'active_selection' : '', 'selection_btn d-flex align-items-center justify-content-between gap-2 bg-white p-2 rounded mb-2 w-100']">
                                    <p>Custom</p>
                                    <p>${{ customPrice }}</p>
                                </button>
                                <input v-if="selectedQuantity == 'custom'" v-model="customQuantity"
                                    @input="handleCustomQuantity" type="number"
                                    class="form-control border-start-0 text-4" placeholder="Custom Quantity" min="0">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="selection_next_btn">
                <button type="button" @click="goNextPage" class="btn customized_btn"> Next <i
                        class="bi bi-arrow-right-circle-fill"></i></button>
            </div>
        </section>
        <section
            class="landing_footer bg-indigo-300 d-flex flex-column flex-lg-row justify-content-between mt-3 mt-lg-0">
            <div class="flex-1 d-flex justify-content-center justify-content-lg-start">
                <img class="landing_page--logo" :src="require('@/assets/img/utils/logo.png')" alt="">
            </div>
            <div
                class="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-4 flex-1 my-3 my-lg-0 text-no-wrap">
                <router-link :to="{ name: 'selection' }"
                    class="text-decoration-none text-gray-900">Contact</router-link>
                <router-link :to="{ name: 'selection' }" class="text-decoration-none text-gray-900">About
                    Us</router-link>
                <router-link :to="{ name: 'selection' }" class="text-decoration-none text-gray-900">Custom
                    Sticker</router-link>
                <router-link :to="{ name: 'selection' }" class="text-decoration-none text-gray-900">Blogs</router-link>
                <router-link :to="{ name: 'selection' }"
                    class="text-decoration-none text-gray-900">Materials</router-link>
            </div>
            <div class="flex-1 d-flex justify-content-center justify-content-lg-end align-items-center gap-3">
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-facebook-f"></i></router-link>
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-instagram"></i></router-link>
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-youtube"></i></router-link>
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-x-twitter"></i></router-link>
            </div>
        </section>
    </div>
</template>

<script>
import { ref } from 'vue';
import { onMounted } from 'vue';
import { startScrolling } from '@/assets/js/selection.js';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const products = ref([]);
        const shapes = ref([]);
        const materials = ref([]);
        const coatings = ref([]);
        const sizes = ref([]);
        const quantities = ref([]);

        const selectedStickerType = ref('');
        const selectedStickerShape = ref('');
        const selectedStickerMaterial = ref('');
        const selectedStickerCoating = ref('');
        const selectedSize = ref('');
        const selectedQuantity = ref('');

        const preSelected = ref({});

        const width = computed(() => store.getters['selection/getWidth']);
        const height = computed(() => store.getters['selection/getHeight']);
        const customWidth = ref(0);
        const customHeight = ref(0);

        const customPrice = computed(() => store.getters['selection/getCustomPrice']);
        const customQuantity = ref(0);

        onMounted(async () => {
            await store.dispatch('selection/setProducts');
            handleSelectStickerType(null);
        })

        const handleSelectStickerType = async (productId) => {
            clearSelectedValue();
            const response = await store.dispatch('selection/setSelectedProduct', productId);
            setSelectedValue(response);
        }

        const handleSelectStickerShape = async (shape) => {
            clearSelectedValue();
            const response = await store.dispatch('selection/setSelectedShape', shape);
            setSelectedValue(response);
        }

        const handleSelectStickerMaterial = async (material) => {
            clearSelectedValue();
            const response = await store.dispatch('selection/setSelectedMaterial', material);
            setSelectedValue(response);
        }

        const handleSelectStickerCoating = async (coating) => {
            clearSelectedValue();
            const response = await store.dispatch('selection/setSelectedCoating', coating)
            setSelectedValue(response);
            setTimeout(() => {
                startScrolling();
            }, 1);
        }

        const handleSelectPredefinedSize = async (size) => {
            clearSelectedValue();
            const response = await store.dispatch('selection/setSelectedSize', size)
            setSelectedValue(response);
        }

        const handleSelectPredefinedQuantity = async (quantity) => {
            clearSelectedValue();
            const response = await store.dispatch('selection/setSelectedQuantity', quantity);
            setSelectedValue(response);
            setTimeout(() => {
                startScrolling();
            }, 1);
        };

        const handleCustomSize = async () => {
            if (customHeight.value > 0 && customWidth.value > 0) {
                const body = {
                    width: customWidth.value,
                    height: customHeight.value
                };
                await store.dispatch('selection/handleCustomSize', body);
            }
        }

        const handleCustomQuantity = async () => {
            await store.dispatch('selection/handleCustomQuantity', customQuantity.value);
        }

        const clearCustomSizes = () => {
            customHeight.value = 0;
            customWidth.value = 0;
        }

        const setSelectedValue = body => {
            selectedStickerType.value = body.selectedProduct.id;
            selectedStickerShape.value = body.selectedShape.id;
            selectedStickerMaterial.value = body.selectedMaterial.id;
            selectedStickerCoating.value = body.selectedCoating.id;
            selectedSize.value = body.selectedSize.id;
            selectedQuantity.value = body.selectedQuantity.id;

            products.value = body.products;
            shapes.value = body.shapes;
            materials.value = body.materials;
            coatings.value = body.coatings;
            sizes.value = body.sizes;
            quantities.value = body.quantities;

            preSelected.value = body;
        }
        const clearSelectedValue = () => {
            selectedStickerType.value = '';
            selectedStickerShape.value = '';
            selectedStickerMaterial.value = '';
            selectedStickerCoating.value = '';
            selectedSize.value = '';
            selectedQuantity.value = '';
        }

        const goNextPage = async() => {
            const body = preSelected.value;
            await store.dispatch('selection/setPreselected', body);
            router.push({ name: 'sticker.mode' });
        }

        return {
            handleSelectStickerType,
            selectedStickerType,
            selectedStickerShape,
            handleSelectStickerShape,
            selectedStickerMaterial,
            handleSelectStickerMaterial,
            selectedStickerCoating,
            handleSelectStickerCoating,
            selectedSize,
            handleSelectPredefinedSize,
            selectedQuantity,
            handleSelectPredefinedQuantity,
            shapes,
            materials,
            coatings,
            products,
            sizes,
            quantities,
            height,
            width,
            customHeight,
            customWidth,
            handleCustomSize,
            customPrice,
            handleCustomQuantity,
            customQuantity,
            clearCustomSizes,
            goNextPage
        }
    }
}
</script>

<style scoped>
.landing_page--logo {
    width: 140px;
}

.landing_footer {
    padding: 10px 36px;
}

.landing_page__footer--social {
    width: 40px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: #212529;
    color: #F8F9FA;
    border-radius: 50%;
}

.sticker_type--img {
    width: 60px;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.small_img {
    height: 30px;
    margin-bottom: 8px;
}

.material_img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    border-radius: 50%;
    transform: scale(1.7);
}

.shape_img {
    min-width: 60px;
    max-height: 60px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.coating_img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.form-control {
    padding: 0.5rem;
}

.form-control:focus {
    box-shadow: none;
    border-color: #dee2e6;
}

.selection_next_btn {
    border: 20px solid #fff;
    border-radius: 999px;
    position: absolute;
    bottom: 0;
    right: 32px;
    transform: translateY(50%);
}

.selection_next_btn .customized_btn {
    padding: 10px 100px;
}

.selection__contianer {
    position: relative;
    margin-bottom: 100px !important;
    padding-bottom: 46px !important;
}

.selection_btn {
    box-sizing: border-box;
    border: 2px solid transparent;
    overflow: hidden;
}

.active_selection {
    font-size: 11px !important;
    border: 2px solid #6610F2;
    font-weight: bold;
}

.active_selection p {
    font-weight: bold;
}

.selection_box {
    height: 80vh;
    display: flex;
    flex-direction: column;
}

.customized_scrolled {
    overflow-y: auto;
}

.customized_scrolled::-webkit-scrollbar {
    width: 5px;
}

.customized_scrolled::-webkit-scrollbar-track {
    background: transparent;
}

.customized_scrolled::-webkit-scrollbar-thumb {
    background: #ADB5BD;
    border-radius: 999px;
}

.customized_scrolled::-webkit-scrollbar-thumb:hover {
    background: #878a8e;
}

.customized_scrolled::-webkit-scrollbar-button {
    display: none;
}

.sticker_size__image--container {
    width: 16px;
}

.sticker_size--img {
    max-height: 20px;
}

@media screen and (max-width: 567px) {
    .landing_page--logo {
        width: 80px;
    }

    .selection_next_btn {
        left: 50%;
        transform: translate(-50%, 50%) !important;
        right: unset !important;
    }

    .material_img {
        width: 45%;
        /* transform: scale(0.9); */
    }

    .coating_img {
        width: 45%;
    }
}

.scrolling-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 1.5rem;
}

.scrolling-text {
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    transform: translateX(-50%);
}
</style>
