<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Text</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3">Add and Customize Text</p>
        <div class="my-3">
            <div class="mb-3">
                <label for="text" class="form-label text-gray-100">Text</label>
                <textarea v-model="text" class="form-control text-3 px-2" id="text" rows="4" placeholder="Write your Text"></textarea>
            </div>
            <button @click="save" class="btn btn-indigo-500 w-100">
                <span v-if="isNew">Add</span>
                <span v-if="!isNew">Update</span>
            </button>
        </div>

        <div>
            <multiselect v-model="selectedFont" :options="options" track-by="id" label="text" placeholder="Select font">
                <template #option="{ option }">
                    <span :style="{ fontFamily: option.font }">{{ option.text }}</span>
                </template>
            </multiselect>
        </div>


        <div class="mb-3">
            <p class="mb-1 text-gray-100">Alignment</p>
            <div class="d-flex gap-2">
                <button @click="changeAlignment('left')"
                    :class="[selectedAlignment == 'left' ? 'active_alignment' : 'bg-gray-900 text-white', 'btn flex-1 alignment-btn']"><i
                        class="bi bi-text-left"></i></button>
                <button @click="changeAlignment('center')"
                    :class="[selectedAlignment == 'center' ? 'active_alignment' : 'bg-gray-900 text-white', 'btn flex-1 alignment-btn']"><i
                        class="bi bi-text-center"></i></button>
                <button @click="changeAlignment('right')"
                    :class="[selectedAlignment == 'right' ? 'active_alignment' : 'bg-gray-900 text-white', 'btn flex-1 alignment-btn']"><i
                        class="bi bi-text-right"></i></button>
            </div>
        </div>
        <div class="mb-3">
            <p class="mb-1 text-gray-100">Default Color</p>
            <div class="color_container mb-3">
                <button class="btn color_choose_btn" @click="() => setColorSelection(color)" v-for="(color, index) in defaultColors" :key="index" :style="{background: color}"></button>
            </div>
            <p class="mb-1 text-gray-100">Custom Color</p>
            <v-color-picker width="100%" hide-inputs v-model="color" @update:modelValue="handleColorChange"></v-color-picker>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import {useStore} from "vuex";

export default {
    props: {
        saveText: Function,
    },
    components: {
        Multiselect,
    },
    setup(props) {
        const store = useStore();
        const selectedTextElement = computed(() => store.getters["stickerPaper/getSelectedTextElement"]);
        const isNew = ref(true);
        const text = ref('');
        const options = ref([
            { id: 1, text: 'Abel', font: 'Abel' },
            { id: 2, text: 'Atomic Age', font: 'Atomic Age' },
            { id: 3, text: 'Black Ops One', font: 'Black Ops One' },
            { id: 4, text: 'Black', font: 'Black' },
            { id: 5, text: 'Blazed', font: 'Blazed' },
            { id: 6, text: 'Bree', font: 'Bree' },
            { id: 7, text: 'Cac Champagne', font: 'Cac Champagne' },
            { id: 8, text: 'Comic Neue', font: 'Comic Neue' },
            { id: 9, text: 'Fv Almelo', font: 'Fv Almelo' },
            { id: 10, text: 'Glyphicons Halflings', font: 'Glyphicons Halflings' },
            { id: 11, text: 'Homemade Apple', font: 'Homemade Apple' },
            { id: 12, text: 'Italiana', font: 'Italiana' },
            { id: 13, text: 'Libre Baskerville', font: 'Libre Baskerville' },
            { id: 14, text: 'Little Days', font: 'Little Days' },
            { id: 15, text: 'Lobster Two', font: 'Lobster Two' },
            { id: 16, text: 'Mossy', font: 'Mossy' },
            { id: 17, text: 'Nixie One', font: 'Nixie One' },
            { id: 18, text: 'Palitoon', font: 'Palitoon' },
            { id: 19, text: 'Quicksand', font: 'Quicksand' },
            { id: 20, text: 'Specialelite', font: 'Specialelite' },
        ]);
        const selectedFont = ref(options.value[0]);
        const defaultColors  = ref([
            '#000000','#545454','#A6A6A6','#FF3030',
            '#FF5757','#FF65C3','#CB6CE6','#CB6CE6',
            '#5E17EB','#7ED957','#C1FF72','#FFBD59',
            '#FFEE59','#FFFFFF','#000000'
        ]);
        const color = ref(defaultColors.value[0]);
        const selectedAlignment = ref('left');

        const changeAlignment = (alignment) => {
            selectedAlignment.value = alignment;
        }

        onMounted(() => {
            if (selectedTextElement.value.data) {
                initText(selectedTextElement.value.data);
            }
        })

        watch(() => selectedTextElement.value, (newValue) => {
            if (newValue.data) {
                initText(newValue.data);
            }
        });

        const initText = (data) => {
            text.value = data.text;
            selectedFont.value = data.style.font;
            selectedAlignment.value = data.style.alignment;
            color.value = data.style.color;
            isNew.value = false;
        }

        const handleColorChange = (_color) => {
            console.log(_color)
            //color.value = _color;
        };

        const setColorSelection = (_color) => {
            color.value = _color;
        }

        const save = () => {
            const textObj = {
                text: text.value,
                style: {
                    font: selectedFont.value,
                    color: color.value,
                    alignment: selectedAlignment.value
                }
            }
            props.saveText(textObj, isNew.value ? null : selectedTextElement.value.textId);
            text.value = '';
            selectedFont.value = options.value[0];
            color.value = defaultColors.value[0];
            selectedAlignment.value = 'left';
        }

        return {
            changeAlignment,
            selectedAlignment,
            handleColorChange,
            setColorSelection,
            defaultColors,
            selectedFont,
            initText,
            options,
            isNew,
            color,
            text,
            save,
        }
    }
}
</script>

<style>
.form-control {
    resize: none;
}

.form-control::placeholder {
    color: #ADB5BD;
}

.form-control:focus {
    box-shadow: none;
}

.active_alignment {
    background-color: #A370F7 !important;
    color: #212529 !important;
}

.alignment-btn {
    font-size: 18px !important;
}

.alignment-btn:active {
    border-color: transparent !important;
}

/* Colors */
.color_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
}

.color_choose_btn {
    width: 100%;
    aspect-ratio: 1/1;
    border: none !important;
}

.more_color_btn {
    background: conic-gradient(from 180deg at 50% 50%, #72F726 0deg, #01FC3D 45deg, #15F4FA 90deg, #0541FF 135deg, #9F31FE 180deg, #FF00C2 225deg, #F91414 270deg, #FDBD01 315deg, #A8F633 360deg)
}

.more_color_btn i {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 20px;
    aspect-ratio: 1/1;
    line-height: 1;
    border-radius: 50%;
}

.more_color_btn:hover>i {
    color: black;
}
</style>
