import axios from "axios";

export const BASE_URL = {
    development: 'http://localhost:3000',
    production: 'https://stickerapp.grsagor.com'
};

function getBaseUrl() {
    return process.env.NODE_ENV === 'production' ? BASE_URL.production : BASE_URL.development;
}

export const HTTP = axios.create({
    baseURL: getBaseUrl()
});
