import { createApp } from 'vue';
import router from './router/router';
import App from './App.vue';
import store from './store';
/* Bootstrap */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/assets/css/style.css';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App);

const vuetify = createVuetify({
  components,
  directives,
});

app.use(vuetify);

app.use(store);

app.use(router);

app.provide('router', router);

app.mount('#app')
