export const clipartsData = [
    {
        "id": 1,
        "thumbnail": "/img/sticker-mode/cliparts/1.png"
    },
    {
        "id": 2,
        "thumbnail": "/img/sticker-mode/cliparts/2.png"
    },
    {
        "id": 3,
        "thumbnail": "/img/sticker-mode/cliparts/3.png"
    },
    {
        "id": 4,
        "thumbnail": "/img/sticker-mode/cliparts/4.png"
    },
    {
        "id": 5,
        "thumbnail": "/img/sticker-mode/cliparts/5.png"
    },
    {
        "id": 6,
        "thumbnail": "/img/sticker-mode/cliparts/6.png"
    },
    {
        "id": 7,
        "thumbnail": "/img/sticker-mode/cliparts/7.png"
    },
    {
        "id": 8,
        "thumbnail": "/img/sticker-mode/cliparts/8.png"
    },
    {
        "id": 9,
        "thumbnail": "/img/sticker-mode/cliparts/9.png"
    },
    {
        "id": 10,
        "thumbnail": "/img/sticker-mode/cliparts/10.png"
    },
    {
        "id": 11,
        "thumbnail": "/img/sticker-mode/cliparts/11.png"
    },
    {
        "id": 12,
        "thumbnail": "/img/sticker-mode/cliparts/12.png"
    },
    {
        "id": 13,
        "thumbnail": "/img/sticker-mode/cliparts/13.png"
    },
    {
        "id": 14,
        "thumbnail": "/img/sticker-mode/cliparts/14.png"
    },
    {
        "id": 15,
        "thumbnail": "/img/sticker-mode/cliparts/15.png"
    },
    {
        "id": 16,
        "thumbnail": "/img/sticker-mode/cliparts/16.png"
    },
    {
        "id": 17,
        "thumbnail": "/img/sticker-mode/cliparts/17.png"
    },
    {
        "id": 18,
        "thumbnail": "/img/sticker-mode/cliparts/18.png"
    },
    {
        "id": 19,
        "thumbnail": "/img/sticker-mode/cliparts/19.png"
    },
    {
        "id": 20,
        "thumbnail": "/img/sticker-mode/cliparts/20.png"
    },
    {
        "id": 21,
        "thumbnail": "/img/sticker-mode/cliparts/21.png"
    },
    {
        "id": 22,
        "thumbnail": "/img/sticker-mode/cliparts/22.png"
    },
    {
        "id": 23,
        "thumbnail": "/img/sticker-mode/cliparts/23.png"
    },
    {
        "id": 24,
        "thumbnail": "/img/sticker-mode/cliparts/24.png"
    },
    {
        "id": 25,
        "thumbnail": "/img/sticker-mode/cliparts/25.png"
    },
    {
        "id": 26,
        "thumbnail": "/img/sticker-mode/cliparts/26.png"
    },
    {
        "id": 27,
        "thumbnail": "/img/sticker-mode/cliparts/27.png"
    },
    {
        "id": 28,
        "thumbnail": "/img/sticker-mode/cliparts/28.png"
    },
    {
        "id": 29,
        "thumbnail": "/img/sticker-mode/cliparts/29.png"
    },
    {
        "id": 30,
        "thumbnail": "/img/sticker-mode/cliparts/30.png"
    },
    {
        "id": 31,
        "thumbnail": "/img/sticker-mode/cliparts/31.png"
    },
    {
        "id": 32,
        "thumbnail": "/img/sticker-mode/cliparts/32.png"
    },
    {
        "id": 33,
        "thumbnail": "/img/sticker-mode/cliparts/33.png"
    },
    {
        "id": 34,
        "thumbnail": "/img/sticker-mode/cliparts/34.png"
    },
    {
        "id": 35,
        "thumbnail": "/img/sticker-mode/cliparts/35.png"
    },
    {
        "id": 36,
        "thumbnail": "/img/sticker-mode/cliparts/36.png"
    },
    {
        "id": 37,
        "thumbnail": "/img/sticker-mode/cliparts/37.png"
    },
    {
        "id": 38,
        "thumbnail": "/img/sticker-mode/cliparts/38.png"
    },
    {
        "id": 39,
        "thumbnail": "/img/sticker-mode/cliparts/39.png"
    },
    {
        "id": 40,
        "thumbnail": "/img/sticker-mode/cliparts/40.png"
    },
    {
        "id": 41,
        "thumbnail": "/img/sticker-mode/cliparts/41.png"
    },
    {
        "id": 42,
        "thumbnail": "/img/sticker-mode/cliparts/42.png"
    },
    {
        "id": 43,
        "thumbnail": "/img/sticker-mode/cliparts/43.png"
    }
];
export const trending = [1,2];
export const popular = [3,4];
export const love = [5,6];
export const handWritten = [7,8];
