<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Sticker Shapes</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-3">Change the Shapes and Cutline</p>
        <div class="mt-3">
            <p>Shapes</p>
            <div class="pt-1 ps-1 pe-1 rounded pb-1 row g-0">
                <div v-for="(shape, index) in shapes" :key="index"
                    :class="[shapes.length == 1 ? 'col-lg-12' : '', shapes.length == 2 ? 'col-lg-6' : '', shapes.length == 3 ? 'col-lg-4' : '', shapes.length >= 4 ? 'col-lg-4' : '', 'col-6 col-md-4 p-1']">
                    <button @click="handleSelectStickerShape(shape)"
                        :class="[selectedShape.id  === shape.id ? 'active_selection' : '', 'selection_btn bg-gray-900 p-2 rounded w-100']">
                        <div>
                            <img class="shape_img" :src="shape.thumbnail" alt="">
                        </div>
                        <div class="scrolling-container">
                            <p class="m-0 scrolling-text text-white">{{ shape.name }}</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script scoped>
import { startScrolling } from '@/assets/js/selection.js';
// import { watch } from 'vue';
import {onMounted, computed} from 'vue';
import {useStore} from "vuex";

export default {
    props: {},
    emits: ['update-selected-shape'],
    setup(props, ctx) {
        const store = useStore();
        const shapes = computed(() => store.getters['selection/getShapes']);
        const selectedShape = computed(() => store.getters['selection/getSelectedShape']);

        onMounted(() => {
            startScrolling();
        })

        const handleSelectStickerShape = async (shape) => {
            ctx.emit('update-selected-shape', shape);
            await store.dispatch('selection/setSelectedShape', shape);
        }

        return {
            handleSelectStickerShape,
            selectedShape,
            shapes
        }
    }
}
</script>

<style scoped>
.shape_img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}
.scrolling-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 1rem;
}

.scrolling-text {
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    transform: translateX(-50%);
    font-size: 0.75rem !important;
}
.active_selection {
    border: 2px solid #6610F2;
}
</style>
