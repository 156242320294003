<script scope>
import {fabric} from "fabric";
import canvasBGPath from '@/assets/img/sticker/bg-transperant.png'

export default {
  mounted() {
    this.createCanvas();
  },
  methods: {
    createCanvas() {
      // Initializing canvas properly
      this.canvas = new fabric.Canvas(this.$refs.canvas);
      //this.$refs.canvas.clientWidth
      this.canvas.setWidth(600);
      this.canvas.setHeight(400);

      // Load image using Image object
      let img = new Image();
      img.src = canvasBGPath;
      img.onload = () => {
        let fabricImg = new fabric.Image(img, {
          width: this.canvas.width,
          height: this.canvas.height,
          left: 0,
          top: 0
        });
        this.canvas.setBackgroundImage(fabricImg, this.canvas.renderAll.bind(this.canvas));
      };

      // Bind mouse event to enable resizing of the canvas
      this.canvas.on('mouse:down', (event) => {
        if (event.target === this.canvas) {
          this.startResize(event);
        }
      });

      //this.addAdditionalItemsWhenInitialized();

      // You can initialize other canvas settings here if needed
    },
    startResize(event) {
      const pointer = this.canvas.getPointer(event.e);
      this.canvas.selection = false;

      // Initial mouse position
      const startX = pointer.x;
      const startY = pointer.y;

      // Initial canvas dimensions
      const startWidth = this.canvas.width;
      const startHeight = this.canvas.height;

      // Bind mousemove and mouseup events to resize the canvas
      this.canvas.on('mouse:move', (event) => {
        const pointer = this.canvas.getPointer(event.e);
        const newWidth = startWidth + (pointer.x - startX);
        const newHeight = startHeight + (pointer.y - startY);
        this.canvas.setWidth(newWidth > 0 ? newWidth : 0);
        this.canvas.setHeight(newHeight > 0 ? newHeight : 0);
        this.canvas.renderAll();
      });

      this.canvas.on('mouse:up', () => {
        this.canvas.off('mouse:move');
        this.canvas.off('mouse:up');
        this.canvas.selection = true;
      });
    },
    addRectangle() {
      // Checking if canvas is properly initialized
      if (!this.canvas) {
        console.error("Canvas is not initialized properly.");
        return;
      }
      const rect = new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        fill:'#007400',
        borderRadius: 5
      });
      this.canvas.add(rect);
    },
    addCircle() {
      if (!this.canvas) {
        console.error("Canvas is not initialized properly.");
        return;
      }
      const circle = new fabric.Circle({
        left: 110,
        top: 110,
        radius: 40,
        fill: '#d70000'
      });
      this.canvas.add(circle);
    },
    addText() {
      if (!this.canvas) {
        console.error("Canvas is not initialized properly.");
        return;
      }
      const text = new fabric.Text('Hello, Fabric!', {
        left: 200,
        top: 200,
        fill: '#000000'
      });
      this.canvas.add(text);
    },
    addImage() {
      if (!this.canvas) {
        console.error("Canvas is not initialized properly.");
        return;
      }

      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        let files =   Array.from(input.files);
        if (!files || !files.length) return;
        console.log(files, e);
        const imgReader = new FileReader();
        imgReader.readAsDataURL(files[0]);
        imgReader.onload = (e) => {
          fabric.Image.fromURL(e.target.result, (img) => {
            img.set({
              // left: 60,
              // top: 60,
              scaleX: 0.1,
              scaleY: 0.1
            });
            this.canvas.add(img);
          });
        }

      };
      input.click();
    },
    addAdditionalItemsWhenInitialized() {
      const rect = new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        fill:'#007400',
        borderRadius: 5
      });

      const circle = new fabric.Circle({
        left: 110,
        top: 110,
        radius: 40,
        fill: '#d70000'
      });

      this.canvas.add(rect);
      this.canvas.add(circle);
    }
  }
}
</script>
<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 p-3">
        <canvas ref="canvas" id="canvas"></canvas>
      </div>
      <div class="col-md-4 p-3">
        <div class="d-flex w-100 rounded mt-3">
          <div class="col"></div>
          <div class="col d-flex flex-column flex-shrink-0 bg-light gap-1">
            <button class="btn btn-sm btn-primary" @click="addRectangle">Add Rectangle</button>
            <button class="btn btn-sm btn-success" @click="addCircle">Add Circle</button>
            <button class="btn btn-sm btn-info" @click="addText">Add Text</button>
            <button class="btn btn-sm btn-warning" @click="addImage">Add Image</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scope>
body {
  min-height: 100vh;
  background: #e7e7e7;
}
#app {
  min-height: 100vh;
  background: #e7e7e7;
}
</style>