import axios from 'axios';

const state = {
    products: [],
    shapes: [],
    materials: [],
    coatings: [],
    sizes: [],
    quantities: [],

    selectedProduct: {},
    selectedShape: {},
    selectedMaterial: {},
    selectedCoating: {},
    selectedSize: {},
    selectedQuantity: {},
    preselected: {},

    width: '',
    height: '',
    customPrice: 0,
};

const getters = {
    getProducts: (state) => state.products,
    getShapes: (state) => state.shapes,
    getMaterials: (state) => state.materials,
    getCoatings: (state) => state.coatings,
    getSizes: (state) => state.sizes,
    getQuantities: (state) => state.quantities,

    getWidth: (state) => state.width,
    getHeight: (state) => state.height,
    getCustomPrice: (state) => state.customPrice,
    getCustomQuantity: (state) => state.customQuantity,

    getSelectedProduct: (state) => state.selectedProduct,
    getSelectedShape: (state) => state.selectedShape,
    getSelectedMaterial: (state) => state.selectedMaterial,
    getSelectedCoating: (state) => state.selectedCoating,
    getSelectedSize: (state) => state.selectedSize,
    getSelectedQuantity: (state) => state.selectedQuantity,
    getPreselected: (state) => state.preselected,
};

const actions = {
    /* Set Selected */
    async getSelectedValue({rootGetters}) {
        const body = {
            selectedProduct: rootGetters['selection/getSelectedProduct'],
            selectedShape: rootGetters['selection/getSelectedShape'],
            selectedMaterial: rootGetters['selection/getSelectedMaterial'],
            selectedCoating: rootGetters['selection/getSelectedCoating'],
            selectedSize: rootGetters['selection/getSelectedSize'],
            selectedQuantity: rootGetters['selection/getSelectedQuantity'],

            products: rootGetters['selection/getProducts'],
            shapes: rootGetters['selection/getShapes'],
            materials: rootGetters['selection/getMaterials'],
            coatings: rootGetters['selection/getCoatings'],
            sizes: rootGetters['selection/getSizes'],
            quantities: rootGetters['selection/getQuantities'],


        }
        return body;
    },
    async setSelectedProduct({ commit, dispatch }, productId) {
        const productData = await axios.get('jsons/products.json');
        if (!productId) {
            commit('setSelectedProduct', productData.data[0]);
        } else {
            const product = productData.data.find(item => item.id == productId);
            commit('setSelectedProduct', product);
        }

        await dispatch('handleSetShapes');
        await dispatch('handleSetMaterials');
        await dispatch('handleSetSizes');
        await dispatch('handleSetCoatings');
        commit('setPrices');

        return await dispatch('getSelectedValue');
    },
    async setSelectedShape({ commit, dispatch }, selectedShape) {
        commit('setSelectedShape', selectedShape);
        return await dispatch('getSelectedValue');
    },
    async setSelectedMaterial({ commit, dispatch }, selectedMaterial) {
        commit('setSelectedMaterial', selectedMaterial);
        await dispatch('handleSetQuantities');
        await dispatch('handleSetCoatings');
        return await dispatch('getSelectedValue');
    },
    async setSelectedCoating({ commit, dispatch }, selectedCoating) {
        commit('setSelectedCoating', selectedCoating);
        return await dispatch('getSelectedValue');
    },
    async setSelectedSize({ commit, dispatch }, selectedSize) {
        await dispatch('setWidth', selectedSize.width);
        await dispatch('setHeight', selectedSize.height);
        commit('setSelectedSize', selectedSize);
        await dispatch('handleSetQuantities');
        return await dispatch('getSelectedValue');
    },
    async setSelectedQuantity({ commit, dispatch }, selectedQuantity) {
        commit('setSelectedQuantity', selectedQuantity);
        return await dispatch('getSelectedValue');
    },
    async setPreselected({ commit }, body) {
        commit('setPreselected', body);
    },

    /* Set the arrays */
    async setProducts({ commit }) {
        const productData = await axios.get('jsons/products.json');
        commit('setProducts', productData.data);
    },
    async setShapes({ commit, dispatch }, shapes) {
        commit('setShapes', shapes);
        await dispatch('setSelectedShape', shapes[0]);
    },
    async setMaterials({ commit }, materials) {
        commit('setMaterials', materials);
    },
    async setSizes({ commit, dispatch }, sizes) {
        commit('setSizes', sizes);
        await dispatch('setSelectedSize', sizes[0]);
    },
    async setCoatings({ commit, dispatch }, coatings) {
        commit('setCoatings', coatings);
        await dispatch('setSelectedCoating', coatings[0])
    },
    async setQuantities({ commit, dispatch }, quantities) {
        commit('setQuantities', quantities);
        commit('setPrices');
        await dispatch('setSelectedQuantity', quantities[0]);
    },

    /* Set Variable Values */
    async setWidth({ commit }, width) {
        commit('setWidth', width);
    },
    async setHeight({ commit }, height) {
        commit('setHeight', height);
    },
    async handleCustomSize({ commit, dispatch }, body) {
        await dispatch('setWidth', body.width);
        await dispatch('setHeight', body.height);
        commit('setPrices');
    },

    /* Handle Change */
    async handleSetShapes({dispatch, rootGetters}) {
        const productShapaData = await axios.get('jsons/product_shapes.json');
        const shapesData = await axios.get('jsons/shapes.json');
        const shapes = productShapaData?.data?.filter(productShape => productShape.product_id == rootGetters['selection/getSelectedProduct'].id)
            .map(productShape => shapesData.data.find(shape => shape.id == productShape.shape_id));

        await dispatch('setShapes', shapes);
    },
    async handleSetMaterials({dispatch, rootGetters}) {
        const productMaterialsData = await axios.get('jsons/product_materials.json');
        const materialsData = await axios.get('jsons/materials.json');
        const materials = productMaterialsData.data.filter(productMaterial => productMaterial.product_id === rootGetters['selection/getSelectedProduct'].id)
            .map(productMaterial => materialsData.data.find(material => material.id === productMaterial.material_id)).filter(productMaterial => productMaterial);

        await dispatch('setMaterials', materials);
        await dispatch('setSelectedMaterial', materials[0]);
    },
    async handleSetSizes ({dispatch, rootGetters}) {
        const productSizesData = await axios.get('jsons/product_sizes.json');
        const sizesData = await axios.get('jsons/sizes.json');
        const sizes = productSizesData.data.filter(productSize => productSize.product_id == rootGetters['selection/getSelectedProduct'].id)
            .map(productSize => sizesData.data.find(size => size.id == productSize.size_id));

        await dispatch('setSizes', sizes);
    },
    async handleSetCoatings ({dispatch, rootGetters}) {
        const productCoatingsData = await axios.get('jsons/product_coating.json');
        const coatingsDataData = await axios.get('jsons/coatings.json');
        const coatings = productCoatingsData.data.filter(productCoating => productCoating.product_id == rootGetters['selection/getSelectedProduct'].id && productCoating.material_id == rootGetters['selection/getSelectedMaterial'].id)
            .map(productCoating => coatingsDataData.data.find(coating => coating.id === productCoating.coating_id));

        await dispatch('setCoatings', coatings);
    },
    async handleSetQuantities ({dispatch, rootGetters}) {
        const quantitiesData = await axios.get('jsons/price_quantities.json');
        if (rootGetters['selection/getSelectedSize'].id != 'custom') {
            const quantities = quantitiesData.data.filter(productQuantity => productQuantity.product_id == rootGetters['selection/getSelectedProduct'].id && productQuantity.material_id == rootGetters['selection/getSelectedMaterial'].id && productQuantity.size_id == rootGetters['selection/getSelectedSize'].id);
            await dispatch('setQuantities', quantities);
        }
    },
    async handleCustomQuantity({ commit }, customQuantity) {
        commit('setCustomPrice', customQuantity);
    }
};

const mutations = {
    setSelectedProduct: (state, selectedProduct) => (state.selectedProduct = selectedProduct),
    setSelectedShape: (state, selectedShape) => (state.selectedShape = selectedShape),
    setSelectedMaterial: (state, selectedMaterial) => (state.selectedMaterial = selectedMaterial),
    setSelectedCoating: (state, selectedCoating) => (state.selectedCoating = selectedCoating),
    setSelectedSize: (state, selectedSize) => (state.selectedSize = selectedSize),
    setSelectedQuantity: (state, selectedQuantity) => (state.selectedQuantity = selectedQuantity),
    setPreselected: (state, preselected) => (state.preselected = preselected),

    /* Handle Change */
    setProducts: async (state, products) => state.products = products,
    setShapes: async (state, shapes) => state.shapes = shapes,
    setMaterials: async (state, materials) => state.materials = materials,
    setSizes: async (state, sizes) => state.sizes = sizes,
    setCoatings: async (state, coatings) => state.coatings = coatings,
    setQuantities: async (state, setQuantities) => state.quantities = setQuantities,

    setWidth: async (state, width) => state.width = width,
    setHeight: async (state, height) => state.height = height,

    setPrices: async (state) => {
        const unitPrice = state.selectedProduct.unitPrice;
        state.quantities.forEach(quantity => {
            const area = state.height * state.width;
            const totalPrice = area * unitPrice;
            quantity.totalPrice = totalPrice * quantity.quantity;
        });
    },

    setCustomPrice: async (state, customQuantity) => {
        const unitPrice = state.selectedProduct.unitPrice;
        const area = state.width * state.height;
        state.customPrice = area * unitPrice * customQuantity;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
