/*import nw_se from "@/assets/img/nw_se.png";
import ne_sw from "@/assets/img/ne_sw.png";
import rotating_arrow from "@/assets/img/rotate.png";
import remove from "@/assets/img/remove.png";
import valign from "@/assets/img/align_v.png";
import halign from "@/assets/img/align_h.png";*/


export function calculateSignedAngle(x1, y1, x2, y2, cx, cy) {
    // Translate points to the origin
    let x1_ = x1 - cx;
    let y1_ = y1 - cy;
    let x2_ = x2 - cx;
    let y2_ = y2 - cy;

    // Calculate dot product
    let dotProduct = x1_ * x2_ + y1_ * y2_;

    // Calculate determinant
    let determinant = x1_ * y2_ - y1_ * x2_;

    // Calculate angle in radians
    let thetaRad = Math.atan2(determinant, dotProduct);

    // Convert angle to degrees
    let thetaDeg = thetaRad * (180 / Math.PI);

    return thetaDeg;
}

export function generateImagePath(element) {
    const bbox = element.type === 'path' ?  element.bbox() : element.parent().bbox();
    return `M${bbox.x} ${bbox.y} H${bbox.x2} V${bbox.y2} H${bbox.x} Z`;
}







/*           === 2nd try ===           */

/*
export const addDragIcon = (position, boundingBox, element) => {
    let {x: posX, y: posY, width: boxWidth, height: boxHeight} = boundingBox;
    const idPrefix = `select${position}Icon`;//=====================
    let icon;
    icon = selectGroup.value.group().id(idPrefix).attr({//=====================
        class: `svgSelect${position} ${position}`,
        'pointer-events': 'all',
    });

    // Calculate the position based on viewBox coordinates
    posX = posX - 10;
    posY = posY - 10;
    const iconSize = 20;
    let x = 0, y = 0;
    let cursor = '';
    let toolbarIcon = '';
    switch (position) {
        case 'NW': { x = posX; y = posY; cursor = 'nw-resize'; toolbarIcon = nw_se; break; }
        case 'NE': { x = posX + boxWidth; y = posY; cursor = 'ne-resize'; toolbarIcon = ne_sw; break; }
        case 'SE': { x = posX + boxWidth; y = posY + boxHeight; cursor = 'se-resize'; toolbarIcon = nw_se; break; }
        case 'SW': { x = posX; y = posY + boxHeight; cursor = 'sw-resize'; toolbarIcon = ne_sw; break; }
        case 'N': { x = (((posX + boxWidth) + posX) / 2); y = posY - 15; cursor = 'e-resize'; toolbarIcon = rotating_arrow; break; } // rotation button
        case 'X': { x = posX + boxWidth + 25; y = posY; cursor = 'pointer'; toolbarIcon = remove; break; } // remove button
        case 'VA': { x = posX + boxWidth + 25; y = posY + 25; cursor = 'pointer'; toolbarIcon = valign; break; } // remove button
        case 'HA': { x = posX + boxWidth + 25; y = posY + 50; cursor = 'pointer'; toolbarIcon = halign; break; } // remove button
        /!*case 'S': { x = (((posX + boxWidth) + posX) / 2); y = posY + boxHeight; cursor = 's-resize'; dragIcon = ns; break; }*!/
        /!* case 'E': { x = 390; y = 195; cursor = 'e-resize'; dragIcon = ew; break; }
        case 'W': { x = -10; y = 195; cursor = 'w-resize'; dragIcon = ew; break; } *!/
    }
    icon.attr({
        fill: '#2a2a2a',
        cursor: cursor,
        transform: `translate(${x}, ${y})`,
    });

    // Create the clip path
    /!*const clipPath = defs.value.clip().id(`${idPrefix}IconClipPath`);
    clipPath.add(draw.value.rect(iconSize, iconSize));*!/

    // Apply the clip path to the icon
    icon.rect(iconSize, iconSize).attr({
        fill: '#575757',
        rx: 5,
        ry: 5,
        class: 'svgIcon',
        id: `${idPrefix}IconBackground`,//=====================
        'pointer-events': 'all',
        //'clip-path': `url(#${idPrefix}IconClipPath)`
    });
    selectButtonIds.value.push(`${idPrefix}IconBackground`);//=====================

    const iconSecondGroup = icon.group().attr({
        'pointer-events': 'none',
        //'clip-path': `url(#${idPrefix}IconClipPath)`,
        transform: 'matrix(1 0 0 1 0 0)',
    });

    iconSecondGroup.image(toolbarIcon).attr({
        transform: 'matrix(1 0 0 1 0 0)',
        x: 2,
        y: 2,
        width: 16,
        height: 16,
        id: `${idPrefix}IconImage`,//=====================
    });

    // Add resize functionality
    let resizingBox;
    let preX;
    let preY;

    if (position === 'X') {
        icon.on('mousedown', (e) => {
            e.stopPropagation();
            removeArtboardElement(element);
        })
    } else if (position === 'VA') {
        icon.on('mousedown', (e) => {
            e.stopPropagation();
            let box;
            box = editorBackgroundPath.value.bbox();//=====================
            element.cy(box.cy);
            addResizeHandlers(element);//=====================
        })
    } else if (position === 'HA') {
        icon.on('mousedown', (e) => {
            e.stopPropagation();
            let box;
            box = editorBackgroundPath.value.bbox();//=====================
            element.cx(box.cx);
            addResizeHandlers(element);//=====================
        })
    } else if (position === 'N') {
        let initAngle = 0;
        let newAngle  = 0;
        let initX;
        let initY;
        icon.draggable()
            .on('dragstart', (event) => {
                const { box } = event.detail;
                currentResizer.value = event.target;
                resizingBox = element.bbox();
                initX = box.x;
                initY = box.y;
            })
            .on('dragmove', (event) => {
                const { box } = event.detail;

                newAngle  = calculateSignedAngle(initX, initY, box.x, box.y, resizingBox.cx, resizingBox.cy);

                // Calculate the delta angle
                let deltaAngle = newAngle - initAngle;
                //console.log(`${initAngle} - ${newAngle} = ${deltaAngle}`)

                // Adjust delta angle to be within -180 to 180 degrees
                if (deltaAngle > 180) {
                    deltaAngle -= 360;
                } else if (deltaAngle < -180) {
                    deltaAngle += 360;
                }

                // Apply the rotation
                element.rotate(initAngle + deltaAngle, resizingBox.cx, resizingBox.cy);

                // Update initial angle for next move
                initAngle = newAngle;

                // Update initial position for next move
                initX = box.x;
                initY = box.y;
            })
            .on('dragend', () => {
                isResizing.value = false;
                currentResizer.value = null;
                resizingBox = null;
                addResizeHandlers(element);//=====================
            });
    } else {
        icon.draggable()
            .on('dragstart', (event) => {
                const { box } = event.detail;
                currentResizer.value = event.target;//=====================
                resizingBox = element.bbox();
                preX = box.x;
                preY = box.y;
            })
            .on('dragmove', (event) => {
                const { handler, box } = event.detail;
                let newWidth = box.width;
                let newHeight = box.height;
                let newX = preX - box.x;
                let newY = preY - box.y;
                //const scale = newWidth / boundingBox.width;
                if (currentResizer.value.classList.contains('SE')) {
                    isResizing.value = true;
                    newWidth = resizingBox.width - newX;
                    newHeight = resizingBox.height - newY;
                    svgSheetGroup.value.size(newWidth, newHeight);

                    if (element.type === 'path') {
                        infoTextHorizontal.value.x(newWidth / 2);
                        infoTextVertical.value.y(newHeight / 2).x(newWidth + 20);
                    }
                } else if (currentResizer.value.classList.contains('SW')) {
                    isResizing.value = true;
                    newWidth = resizingBox.width + newX;
                    newHeight = resizingBox.height - newY;
                    element.move(resizingBox.x - newX, resizingBox.y);
                    handler.move(resizingBox.x - newX, resizingBox.y);
                    svgSheetGroup.value.size(resizingBox.x - newX, resizingBox.y);
                    if (element.type === 'path') {
                        console.log('infoTextVertical.value', event.detail)
                        infoTextHorizontal.value.x((resizingBox.width - newX) / 2);
                        infoTextVertical.value.x(resizingBox.width + 20).y((resizingBox.height - newY) / 2);
                    }
                } else if (currentResizer.value.classList.contains('NE')) {
                    isResizing.value = true;
                    newWidth = resizingBox.width - newX;
                    newHeight = resizingBox.height + newY;
                    //element.move(resizingBox.x, resizingBox.y - newY);
                    handler.move(resizingBox.x, resizingBox.y - newY);
                    svgSheetGroup.value.size(resizingBox.x, resizingBox.y - newY);
                    if (element.type === 'path') {
                        infoTextHorizontal.value.x(newWidth / 2).y(resizingBox.y - newY - 30);
                        infoTextVertical.value.y((resizingBox.height - newY) / 2).x(newWidth + 20);
                    }
                } else if (currentResizer.value.classList.contains('NW')) {
                    isResizing.value = true;
                    newWidth = resizingBox.width + newX;
                    newHeight = resizingBox.height + newY;
                    element.move(resizingBox.x - newX, resizingBox.y - newY);
                    handler.move(resizingBox.x - newX, resizingBox.y - newY);
                    svgSheetGroup.value.size(resizingBox.x - newX, resizingBox.y - newY);
                    if (element.type === 'path') {
                        infoTextHorizontal.value.x(newWidth / 2).y(resizingBox.y - newY - 30);
                        infoTextVertical.value.y((resizingBox.height - newY) / 2).x(newWidth + 20);
                    }
                }

                let elementBox = element.bbox();
                if (isResizing.value) {
                    if (element.type === 'path' && !backpaperEdit.value) {
                        element.size(newWidth, newHeight);
                        editorBackgroundPath.value.size(newWidth, newHeight);
                        let viewPath = editorBackgroundPath.value.attr('d');
                        svgSheetGroup.value.attr({d: viewPath});
                        svgViewPath.value = viewPath;
                    } else if (element.type === 'text') {
                        const scaleFactor = newHeight / elementBox.height;
                        /!*const newFontSize = element.font('size') * scaleFactor;
                        element.font('size', newFontSize);*!/

                        let fontSize = element.font('data-font-size');
                        element.font('size', Math.ceil(fontSize * scaleFactor *.95));
                        element.attr({
                            'data-font-size': Math.ceil(fontSize * scaleFactor *.95)
                        });
                        /!*element.font('size', fontSize);
                        element.attr({'data-font-size': fontSize});
                        //let elementBox = element.bbox();
                        let editorBox = editorBackgroundPath.value.bbox();
                        const minFontSize = 10; // Minimum font size
                        const maxFontSize = 50; // Maximum font size
                        const minViewportWidth = 100; // Minimum viewport width
                        //const maxViewportWidth = 1200; // Maximum viewport width
                        //const viewportWidth = window.innerWidth;

                        const fontSize = minFontSize + (maxFontSize - minFontSize) * ((editorBox.width - minViewportWidth) / (editorBox.width - minViewportWidth));
                        element.font('size', fontSize);
                        element.attr({'data-font-size': fontSize});*!/
                    } else {
                        element.size(newWidth, newHeight);
                    }
                }
            })
            .on('dragend', (event) => {
                isResizing.value = false;
                currentResizer.value = null;
                resizingBox = null;
                const { box } = event.detail;
                console.log('dragend', box)
                /!*if (element.type === 'path' && !backpaperEdit.value) {
                    const clientRect = svgSheetGroup.value.bbox();
                    console.log(draw.value.viewbox())
                    console.log(clientRect)
                    draw.value.viewbox(clientRect.x, clientRect.y, clientRect.width * 2, clientRect.height * 2);
                }*!/
                addResizeHandlers(element);//=====================
            });
    }
};*/



/*           === 1st try ===           */
/*export const initArtboard = (svgElement, preSelected, viewPortWidth, viewPortHeight, viewBoxWidth, viewBoxHeight, backgroundColor) => {
    let _path;
    let height = Number(preSelected.value.selectedSize.height || '1');
    let width = Number(preSelected.value.selectedSize.width || '1');
    let r = height / width;
    if (r > 1) {
        _path = 'M32.14285754053938 -34.99999641486447L32.14285754053938 405.0000173603407L312.8571537365912 405.0000173603407L312.8571537365912 -34.99999641486447Z';
    } else if (r < 1) {
        _path = 'M-52.74869068629621 0L-52.74869068629621 406.80631514275433L530.1701786737266 406.80631514275433L530.1701786737266 0Z ';
    } else {
        _path = 'M0,0 L0,400 L400,400 L400,0 z';
    }
    const svgViewPath = _path;
    const draw = SVG(svgElement.value).attr({
        'size': `${viewPortWidth.value}, ${viewPortHeight.value}`,
        'viewBox': `-400 -100 ${viewBoxWidth.value} ${viewBoxHeight.value}`,
        'preserveAspectRatio': 'xMidYMid meet',
        'fill': '#e7e7e7',
        'align': "center"
    });

    const defs = draw.defs();
    const pattern = defs.findOne('#backgroundPattern');
    pattern.find('rect').attr({ fill: backgroundColor.value });
    const preBottomLayerGroup = draw.group().id('preBottomLayer');
    const svgSheetGroup = draw.path(svgViewPath).attr({
        transform: 'matrix(1 0 0 1 0 0)',
        class: 'svgSheet single',
        style: 'fill: none;stroke:none'
    });
    const containerGroup = draw.group().id('container').attr({
        transform: 'matrix(1 0 0 1 0 0)',
    });

    const editorBackgroundPath = containerGroup.path(svgViewPath).id('editorBackground').fill(pattern);
    editorBackgroundPath.attr({
        filter: 'url(#drop_shadow)',
        strokeWidth: 0.25,
        stroke: "#dedede"
    });

    editorBackgroundPath.on('click', () => {
        addResizeHandlers(editorBackgroundPath);
    });

    addHoverEffectHandler(editorBackgroundPath);

    return {
        svgViewPath,
        draw,
        defs,
        pattern,
        preBottomLayerGroup,
        svgSheetGroup,
        containerGroup,
        editorBackgroundPath,
    };
}*/
