<script>
/* eslint-disable */
import {computed, onMounted, ref, watch} from "vue";
import {SVG} from "@svgdotjs/svg.js";
import nw_se from "@/assets/img/nw_se.png";
import ne_sw from "@/assets/img/ne_sw.png";
import rotating_arrow from "@/assets/img/rotate.png";
import remove from "@/assets/img/remove.png";
import valign from "@/assets/img/align_v.png";
import halign from "@/assets/img/align_h.png";
import {HTTP} from "@/config/api";
import {calculateSignedAngle, generateImagePath} from "@/components/Sticker-mode/utils";
import {useStore} from "vuex";

export default {
    emits: ['is-front-paper-upload'],
    props: {
        showToolbarCanvas: Function,
        labelOffset: Number,
        isFrontPaperUpload: {
            type: Boolean,
            required: true
        }
    },
    setup(props, ctx) {
        const store = useStore();
        const preSelected = ref(JSON.parse(JSON.stringify(store.getters['selection/getPreselected'])));
        const reactiveSelectedMaterial = computed(() => store.getters['selection/getSelectedMaterial']);
        const reactiveSelectedSize = computed(() => store.getters['selection/getSelectedSize']);
        const svgViewPath = computed(() => store.getters["stickerPaper/getSvgViewPath"]);
        const viewPortWidth = computed(() => store.getters["stickerPaper/getViewPortWidth"]);
        const viewPortHeight = computed(() => store.getters["stickerPaper/getViewPortHeight"]);
        const viewBoxWidth = computed(() => store.getters["stickerPaper/getViewBoxWidth"]);
        const viewBoxHeight = computed(() => store.getters["stickerPaper/getViewBoxHeight"]);
        const patternSize = computed(() => store.getters["stickerPaper/getPatternSize"]);

        const draw = ref(null);
        const defs = ref(null);
        const pattern = ref(null);
        const preBottomLayerGroup = ref(null);
        const svgSheetGroup = ref(null);
        const containerGroup = ref(null);
        const partGroupClipPath = ref(null);
        const containerChildGroup = ref(null);
        const editorBackgroundPath = ref(null);
        const selectLayerGroup = ref(null);
        const hoverRect = ref(null);
        const infoLayerGroup = ref(null);
        const infoTextHorizontal = ref(null);
        const infoTextVertical = ref(null);
        const selectGroup = ref(null);
        const svgElement = ref();
        const selectedImage = ref(preSelected.value.selectedMaterial.image);
        const selectButtonIds = ref([]);
        const activeElement = ref(null);
        const selectedTextElement = ref({});
        const isResizing = ref(false);
        const currentResizer = ref(null);

        const defaultColors = computed(() => store.getters["stickerPaper/getBackgroundColors"]);
        const backgroundColor = ref(defaultColors.value[0]);
        const cutlines = ref(store.getters['stickerPaper/getCutlines']);
        const shapeData = ref({});

        onMounted(async () => {
            initArtboard();
        });

        watch(
            [reactiveSelectedMaterial, reactiveSelectedSize],
            ([newSelectedMaterial, newSelectedSize]) => {
                preSelected.value.selectedMaterial = newSelectedMaterial;
                preSelected.value.selectedSize = newSelectedSize;
                selectedImage.value = preSelected.value.selectedMaterial.image;
            }
        );

        watch({
            selectedImage: (newImage) => {
                const patternImage = svgElement.value.querySelector('pattern image');
                if (patternImage) {
                    patternImage.setAttribute('href', newImage);
                }
            },
            backgroundColor: (newColor) => {
                const rectElement = svgElement.value.querySelector('#backgroundPattern rect');
                if (rectElement) {
                    rectElement.setAttribute('fill', newColor);
                }
            },
            /*backgroundColor: (newColor) => {
                const patternRect = svgElement.value.querySelector('pattern rect');
                if (patternRect) {
                    patternRect.setAttribute('fill', newColor);
                }
            },*/
        });

        const updateSelectedShape = (shapePath) => {
            svgSheetGroup.value.attr({
                d: shapePath
            });
            editorBackgroundPath.value.attr({
                d: shapePath
            });
            shapeData.value = editorBackgroundPath.value.bbox();
        }

        const getPathDimensions = (d) => {
            const draw = SVG().size(0, 0).hide();
            const path = draw.path(d);
            const bbox = path.bbox();
            draw.remove();
            return {
                x: bbox.x,
                y: bbox.y,
                width: bbox.width,
                height: bbox.height,
            };
        }

        const initArtboard = () => {
            let _path = cutlines.value[preSelected.value.selectedShape.type || 'square'];
            let height = Number(preSelected.value.selectedSize.height || '1');
            let width = Number(preSelected.value.selectedSize.width || '1');
            let r = height / width;
            store.dispatch("stickerPaper/setSvgViewPath", _path);
            draw.value = SVG(svgElement.value).attr({
                'size': `${viewPortWidth.value}, ${viewPortHeight.value}`,
                'viewBox': `-400 -100 ${viewBoxWidth.value} ${viewBoxHeight.value}`,
                'preserveAspectRatio': 'xMidYMid meet',
                'fill': '#e7e7e7',
                'align': "center"
            });

            defs.value = draw.value.defs();
            pattern.value = defs.value.findOne('#backgroundPattern');
            pattern.value.find('rect').attr({ fill: backgroundColor.value });
            preBottomLayerGroup.value = draw.value.group().id('preBottomLayer');
            svgSheetGroup.value = draw.value.path(svgViewPath.value).attr({
                transform: 'matrix(1 0 0 1 0 0)',
                class: 'svgSheet single',
                style: 'fill: none;stroke:none'
            });
            containerGroup.value = draw.value.group().id('container').attr({
                transform: 'matrix(1 0 0 1 0 0)',
            });

            /* ================================================== */
            /*               Editor Background Layer              */
            /* ================================================== */
            editorBackgroundPath.value = containerGroup.value.path(svgViewPath.value).id('editorBackground').fill(pattern.value);
            editorBackgroundPath.value.attr({
                filter: 'url(#drop_shadow)',
                strokeWidth: 0.25,
                stroke: "#dedede"
            });

            /* ================================================== */
            /*               Container Element Group              */
            /* ================================================== */
            partGroupClipPath.value = defs.value.findOne('#partGroupClipPath');
            containerChildGroup.value = containerGroup.value.group().id('containerChildGroup');
            containerChildGroup.value.clipWith(partGroupClipPath.value);

            /*editorBackgroundPath.value.on('click', () => {
                addResizeHandlers(editorBackgroundPath.value);
            });*/

            //addHoverEffectHandler(editorBackgroundPath.value);

            /* ================================================== */
            /*             Dimension Information Layer            */
            /* ================================================== */
            infoLayerGroup.value = draw.value.group().id('infoLayer').attr({ 'transform': 'matrix(1 0 0 1 0 0)' });
            let editorPathBox = editorBackgroundPath.value.bbox();
            shapeData.value = editorPathBox;
            infoTextHorizontal.value = infoLayerGroup.value.text(`${preSelected.value.selectedSize.width}"`).id('infoText1').x(editorPathBox.x + (editorPathBox.width / 2)).y(editorPathBox.y - props.labelOffset).attr({
                'fill': '#858585',
                'font-weight': 'bold',
                'space': 'preserve',
                'font-size': "14",
                'text-anchor': "middle",
                'transform': "matrix(1 0 0 1 0 0)"
            });
            infoTextVertical.value = infoLayerGroup.value.text(`${preSelected.value.selectedSize.height}"`).id('infoText2').x(editorPathBox.x + editorPathBox.width + props.labelOffset).y(editorPathBox.y + (editorPathBox.height / 2)).attr({
                'fill': '#858585',
                'font-weight': 'bold',
                'space': 'preserve',
                'font-size': "14",
                'text-anchor': "middle",
                'transform': "matrix(1 0 0 1 0 0)"
            });

            /* ================================================== */
            /*               Element Selection Layer              */
            /* ================================================== */
            selectLayerGroup.value = draw.value.group().id('selectLayerGroup');
            selectGroup.value = selectLayerGroup.value.group().id('selectGroup').attr({
                transform: 'matrix(1 0 0 1 0 0)',
                style: 'display: none;',
            });

            // Event listener to hide selectGroup when clicking outside
            draw.value.on('mousedown', (e) => {
                if (selectButtonIds.value.indexOf(e.target.id) === -1) {
                    selectButtonIds.value = [];
                    selectGroup.value.hide();
                }
            });
        };

        const addDragIcon = (position, boundingBox, element) => {
            let {x: posX, y: posY, width: boxWidth, height: boxHeight} = boundingBox;
            const idPrefix = `select${position}Icon`;//=====================
            let icon;
            icon = selectGroup.value.group().id(idPrefix).attr({//=====================
                class: `svgSelectTool svgSelect${position} ${position}`,
                'pointer-events': 'all',
            });

            // Calculate the position based on viewBox coordinates
            posX = posX - 10;
            posY = posY - 10;
            const iconSize = 20;
            let x = 0, y = 0;
            let cursor = '';
            let toolbarIcon = '';
            switch (position) {
                case 'NW': { x = posX; y = posY; cursor = 'nw-resize'; toolbarIcon = nw_se; break; }
                case 'NE': { x = posX + boxWidth; y = posY; cursor = 'ne-resize'; toolbarIcon = ne_sw; break; }
                case 'SE': { x = posX + boxWidth; y = posY + boxHeight; cursor = 'se-resize'; toolbarIcon = nw_se; break; }
                case 'SW': { x = posX; y = posY + boxHeight; cursor = 'sw-resize'; toolbarIcon = ne_sw; break; }
                case 'N': { x = (((posX + boxWidth) + posX) / 2); y = posY - 15; cursor = 'e-resize'; toolbarIcon = rotating_arrow; break; } // rotation button
                case 'X': { x = posX + boxWidth + 25; y = posY; cursor = 'pointer'; toolbarIcon = remove; break; } // remove button
                case 'VA': { x = posX + boxWidth + 25; y = posY + 25; cursor = 'pointer'; toolbarIcon = valign; break; } // remove button
                case 'HA': { x = posX + boxWidth + 25; y = posY + 50; cursor = 'pointer'; toolbarIcon = halign; break; } // remove button
                /*case 'S': { x = (((posX + boxWidth) + posX) / 2); y = posY + boxHeight; cursor = 's-resize'; dragIcon = ns; break; }*/
                /* case 'E': { x = 390; y = 195; cursor = 'e-resize'; dragIcon = ew; break; }
                case 'W': { x = -10; y = 195; cursor = 'w-resize'; dragIcon = ew; break; } */
            }
            icon.attr({
                fill: '#2a2a2a',
                cursor: cursor,
                transform: `translate(${x}, ${y})`,
            });

            // Create the clip path
            /*const clipPath = defs.value.clip().id(`${idPrefix}IconClipPath`);
            clipPath.add(draw.value.rect(iconSize, iconSize));*/

            // Apply the clip path to the icon
            icon.rect(iconSize, iconSize).attr({
                fill: '#575757',
                rx: 5,
                ry: 5,
                class: 'svgIcon',
                id: `${idPrefix}IconBackground`,//=====================
                'pointer-events': 'all',
                //'clip-path': `url(#${idPrefix}IconClipPath)`
            });
            selectButtonIds.value.push(`${idPrefix}IconBackground`);//=====================

            const iconSecondGroup = icon.group().attr({
                'pointer-events': 'none',
                //'clip-path': `url(#${idPrefix}IconClipPath)`,
                transform: 'matrix(1 0 0 1 0 0)',
            });

            iconSecondGroup.image(toolbarIcon).attr({
                transform: 'matrix(1 0 0 1 0 0)',
                x: 2,
                y: 2,
                width: 16,
                height: 16,
                id: `${idPrefix}IconImage`,//=====================
            });

            // Add resize functionality
            let resizingBox;
            let preX;
            let preY;

            if (position === 'X') {
                icon.on('mousedown', (e) => {
                    e.stopPropagation();
                    removeArtboardElement(element);
                })
            } else if (position === 'VA') {
                icon.on('mousedown', (e) => {
                    e.stopPropagation();
                    let box;
                    box = svgSheetGroup.value.bbox();
                    element.cy(box.cy);
                    //element.cy(box.y + box.height * .5);
                    //element.y(shapeY.value + shapeHeight.value*.5);
                    /*element.transform({
                        translateY: '-50%'
                    });*/
                    //alignElementVertically(element, editorBackgroundPath.value);
                    addResizeHandlers(element);
                })
            } else if (position === 'HA') {
                icon.on('mousedown', (e) => {
                    e.stopPropagation();
                    let box;
                    box = svgSheetGroup.value.bbox();
                    element.cx(box.cx);
                    //element.cx(box.x + box.width * .5);

                    /*element.x(shapeX.value + shapeWidth.value*.5);
                    element.transform({
                        translateX: '-50%'
                    });*/
                    //alignElementHorizontally(element, editorBackgroundPath.value);
                    addResizeHandlers(element);
                })
            } else if (position === 'N') {
                let initAngle = 0;
                let newAngle  = 0;
                let initX;
                let initY;
                icon.draggable()
                    .on('dragstart', (event) => {
                        const { box } = event.detail;
                        currentResizer.value = event.target;
                        resizingBox = element.bbox();
                        initX = box.x;
                        initY = box.y;
                        selectGroup.value.hide();
                    })
                    .on('dragmove', (event) => {
                        const { box } = event.detail;

                        newAngle  = calculateSignedAngle(initX, initY, box.x, box.y, resizingBox.cx, resizingBox.cy);

                        // Calculate the delta angle
                        let deltaAngle = newAngle - initAngle;
                        //console.log(`${initAngle} - ${newAngle} = ${deltaAngle}`)

                        // Adjust delta angle to be within -180 to 180 degrees
                        if (deltaAngle > 180) {
                            deltaAngle -= 360;
                        } else if (deltaAngle < -180) {
                            deltaAngle += 360;
                        }

                        // Apply the rotation
                        element.rotate(initAngle + deltaAngle, resizingBox.cx, resizingBox.cy);

                        // Update initial angle for next move
                        initAngle = newAngle;

                        // Update initial position for next move
                        initX = box.x;
                        initY = box.y;
                    })
                    .on('dragend', () => {
                        isResizing.value = false;
                        currentResizer.value = null;
                        resizingBox = null;
                        addResizeHandlers(element);
                    });
            } else {
                /*const calculateInfoTextOffset = (element, newWidth, newHeight, direction) => {
                    const originalWidth = resizingBox.width;
                    const originalHeight = resizingBox.height;

                    let horizontalOffset = 0.5; // Center by default
                    let verticalOffset = 0.5; // Center by default

                    if (element.type === 'path') {
                        if (direction.includes('E')) {
                            horizontalOffset = (originalWidth - element.attr('width')) / originalWidth; // Offset from right edge
                        }
                        if (direction.includes('S')) {
                            verticalOffset = (originalHeight - element.attr('height')) / originalHeight; // Offset from bottom edge
                        }
                    }

                    const newX = element.attr('x') + (direction.includes('NE') || direction.includes('SE') ? horizontalOffset * newWidth : 0);
                    const newY = element.attr('y') + (direction.includes('SE') || direction.includes('SW') ? verticalOffset * newHeight : 0);

                    return { x: newX, y: newY };
                }*/
                icon.draggable()
                    .on('dragstart', (event) => {
                        const { box } = event.detail;
                        currentResizer.value = event.target;
                        resizingBox = element.bbox();
                        preX = box.x;
                        preY = box.y;
                        selectGroup.value.hide();
                    })
                    .on('dragmove', (event) => {
                        const { handler, box } = event.detail;
                        let newWidth = box.width;
                        let newHeight = box.height;
                        let newX = preX - box.x;
                        let newY = preY - box.y;
                        if (currentResizer.value.classList.contains('SE')) {
                            isResizing.value = true;
                            newWidth = resizingBox.width - newX;
                            newHeight = resizingBox.height - newY;
                            if (element.type === 'path') {
                                svgSheetGroup.value.size(newWidth, newHeight);
                                infoTextHorizontal.value.x(newWidth / 2);
                                infoTextVertical.value.y(newHeight / 2).x(newWidth + 20);
                            }
                        } else if (currentResizer.value.classList.contains('SW')) {
                            isResizing.value = true;
                            newWidth = resizingBox.width + newX;
                            newHeight = resizingBox.height - newY;
                            element.move(resizingBox.x - newX, resizingBox.y);
                            handler.move(resizingBox.x - newX, resizingBox.y);
                            if (element.type === 'path') {
                                svgSheetGroup.value.size(resizingBox.x - newX, resizingBox.y);
                                infoTextHorizontal.value.x((resizingBox.width - newX) / 2);
                                infoTextVertical.value.x(resizingBox.width + 20).y((resizingBox.height - newY) / 2);
                            }
                        } else if (currentResizer.value.classList.contains('NE')) {
                            isResizing.value = true;
                            newWidth = resizingBox.width - newX;
                            newHeight = resizingBox.height + newY;
                            handler.move(resizingBox.x, resizingBox.y - newY);
                            if (element.type === 'path') {
                                svgSheetGroup.value.size(resizingBox.x, resizingBox.y - newY);
                                infoTextHorizontal.value.x(newWidth / 2).y(resizingBox.y - newY - 30);
                                infoTextVertical.value.y((resizingBox.height - newY) / 2).x(newWidth + 20);
                            }
                        } else if (currentResizer.value.classList.contains('NW')) {
                            isResizing.value = true;
                            newWidth = resizingBox.width + newX;
                            newHeight = resizingBox.height + newY;
                            element.move(resizingBox.x - newX, resizingBox.y - newY);
                            handler.move(resizingBox.x - newX, resizingBox.y - newY);
                            if (element.type === 'path') {
                                svgSheetGroup.value.size(resizingBox.x - newX, resizingBox.y - newY);
                                infoTextHorizontal.value.x(newWidth / 2).y(resizingBox.y - newY - 30);
                                infoTextVertical.value.y((resizingBox.height - newY) / 2).x(newWidth + 20);
                            }
                        }

                        let elementBox = element.bbox();
                        if (isResizing.value) {
                            if (element.type === 'path') {
                                element.size(newWidth, newHeight);
                                editorBackgroundPath.value.size(newWidth, newHeight);
                                let viewPath = editorBackgroundPath.value.attr('d');
                                svgSheetGroup.value.attr({d: viewPath});
                                svgViewPath.value = viewPath;
                            } else if (element.type === 'text') {
                                const scaleFactor = newHeight / elementBox.height;
                                let fontSize = element.font('data-font-size');
                                element.font('size', Math.ceil(fontSize * scaleFactor *.95));
                                element.attr({
                                    'data-font-size': Math.ceil(fontSize * scaleFactor *.95)
                                });
                            } else {
                                element.size(newWidth, newHeight);
                            }
                        }
                    })
                    .on('dragend', (event) => {
                        isResizing.value = false;
                        currentResizer.value = null;
                        resizingBox = null;
                        const { box } = event.detail;
                        addResizeHandlers(element);
                    });
            }
        };

        const updateBackgroundImage = (imagePath) => {
            selectedImage.value = imagePath;
            updatePatternImage();
        };

        const updatePatternImage = (imagePath) => {
            draw.value.find('#backgroundPattern image').attr('href', imagePath);
        };

        const updateBackgroundColor = (color) => {
            backgroundColor.value = color;
            const rectElement = svgElement.value.querySelector('#backgroundPattern rect');
            if (rectElement) {
                rectElement.setAttribute('fill', color);
            }
        };

        const saveText = (data, updatedTextId = null) => {
            let textId;
            if (updatedTextId) {
                let textElement = SVG(`#${updatedTextId} text`);
                if (textElement) {
                    textElement.text(data.text);
                    textElement.attr({
                        'font-family': data.style.font.font,
                        // 'font-size': '24px',
                        'text-align': data.style.alignment,
                        fill: data.style.color,
                        'data-font-id': data.style.font.id,
                        'data-font-font': data.style.font.font,
                        'data-font-text': data.style.font.text
                    });
                    addResizeHandlers(textElement);
                    const payload = {
                        ...data,
                        id: updatedTextId,
                        type: 'text'
                    };
                    store.dispatch('sticker/updateLayer', payload);
                }
                selectedTextElement.value = {};
                return;
            }

            textId = `text_${Date.now()}`;
            let containerTextPart = containerChildGroup.value.group().id(textId).attr({
                class: 'svgElementPart',
                transform: 'matrix(1 0 0 1 0 0)',
                'clip-path': 'none'
            });
            const textElementId = `textElement_${Date.now()}`;
            const textElement = containerTextPart.text(data.text).x(200).y(200).attr({
                'font-family': data.style.font.font,
                'font-size': '24px',
                'text-align': data.style.alignment,
                'fill': data.style.color,
                'data-font-id': data.style.font.id,
                'data-font-font': data.style.font.font,
                'data-font-text': data.style.font.text,
                'data-font-size': 24,
                'id': textElementId,
                'transform': 'matrix(1 0 0 1 0 0)'
                //lengthAdjust:"spacingAndGlyphs"
            });

            addHoverEffectHandler(textElement);

            textElement.on('click', () => {
                addResizeHandlers(textElement);
                props.showToolbarCanvas('stickerModelTools', 2);
                data.text = textElement.text();
                store.dispatch("stickerPaper/setSelectedTextElement", {
                    containerTextPart: containerTextPart,
                    text: text,
                    textId: textId,
                    data: data
                });
            });

            addElementDragHandler(text);
            store.dispatch('sticker/addLayer', {
                ...data,
                id: textId,
                type: 'text'
            });
        };

        const addClipart = async (clipart) => {
            let clipart_Id = `clipart_${Date.now()}`;
            let containerClipartPart;
            containerClipartPart = containerChildGroup.value.group().id(clipart_Id).attr({
                class: 'svgElementPart',
                transform: 'matrix(1 0 0 1 0 0)',
                'clip-path': 'none'
            });
            const clipArtElementId = `clipArtElement_${Date.now()}`;
            const clipArtElement = containerClipartPart.image(clipart.thumbnail);
            clipArtElement.size(100, 100).attr({ x: 0, y: 0, id: clipArtElementId, transform: 'matrix(1 0 0 1 0 0)' });

            addHoverEffectHandler(clipArtElement);

            clipArtElement.on('click', () => {
                addResizeHandlers(clipArtElement);
            });

            addElementDragHandler(clipArtElement);
            await store.dispatch('sticker/addLayer', {
                ...clipart,
                id: clipart_Id,
                type: 'clipart'
            });
        };

        const addImage = () => {
            let input = document.createElement('input');
            input.type = 'file';
            input.onchange = async () => {
                let files = Array.from(input.files);
                if (!files || !files.length) return;

                ctx.emit('is-front-paper-upload', true);

                const form = new FormData();
                form.append('file', files[0]);

                const response = await HTTP.post('api/v1/files/store', form);
                const data = response.data;

                let imageId = data.file;
                previewImages(imageId, data.file_path);

                ctx.emit('is-front-paper-upload', false);

                let containerImagePart;
                store.dispatch('sticker/addLayer', {
                    id: imageId,
                    type: 'image',
                    img: data.file_path
                });
                containerImagePart = containerChildGroup.value.group().id(imageId).attr({
                    class: 'svgElementPart',
                    transform: 'matrix(1 0 0 1 0 0)',
                    'clip-path': 'none'
                });

                const imgElementId = `imgElement_${Date.now()}`;
                const imgElement = containerImagePart.image(data.file_path);
                imgElement.size(100, 100).attr({ x: 0, y: 0, id: imgElementId, transform: 'matrix(1 0 0 1 0 0)' });

                addHoverEffectHandler(imgElement);

                imgElement.on('click', () => {
                    addResizeHandlers(imgElement);
                });

                addElementDragHandler(imgElement);
            };
            input.click();
        };

        const previewImages = (imageId, file) => {
            store.dispatch('sticker/addImagePreviews', {
                id: imageId,
                img: file
            });
        };

        const disableHoverOnDrag = () => {
            if (hoverRect.value) {
                hoverRect.value.remove();
                hoverRect.value = null;
            }
        }

        const addHoverEffectHandler = (element) => {
            // Add event listeners to the background container
            element.on('mouseover', () => {
                if (!hoverRect.value) {
                    const editorBackgroundPathValue = generateImagePath(element);
                    hoverRect.value = selectLayerGroup.value
                        .path(editorBackgroundPathValue)
                        //.path("M 0 21.6851 C -1.46668e-15 9.70874 9.70874 2.20002e-15 21.6851 0 C 21.6851 0 122.882 0 122.882 0 C 134.858 7.33339e-16 144.567 9.70874 144.567 21.6851 C 144.567 21.6851 144.567 122.882 144.567 122.882 C 144.567 134.858 134.858 144.567 122.882 144.567 C 122.882 144.567 21.6851 144.567 21.6851 144.567 C 9.70874 144.567 1.46668e-15 134.858 0 122.882 C 0 122.882 0 21.6851 0 21.6851")
                        .stroke({color: '#575757', width: 1})
                        .fill('none');
                    //.move(225, 75);
                }
            });

            element.on('mouseout', () => {
                if (hoverRect.value) {
                    hoverRect.value.remove();
                    hoverRect.value = null;
                }
            });
        }

        const addResizeHandlers = (element) => {
            const boundingBox = element.type === 'path' ?  element.bbox() : element.parent().bbox();
            selectGroup.value.clear();
            selectGroup.value.rect(boundingBox.width, boundingBox.height).id('selectGroupSelectedMarker').attr({
                x: boundingBox.x,
                y: boundingBox.y,
                width: boundingBox.width,
                height: boundingBox.height,
                fill: 'none',
                stroke: "black",
                strokeWidth: ".2"
            });

            const corners = ['NW', 'NE', 'SE', 'SW'];
            corners.forEach(corner => {
                addDragIcon(corner, boundingBox, element);
            });

            //const sides = ['N', 'E', 'S', 'W'];
            if (element.type !== 'path') {
                const sides = ['N', 'X', 'VA', 'HA'];
                sides.forEach(side => {
                    addDragIcon(side, boundingBox, element);
                });
            }
            selectGroup.value.show();
        }

        const addElementDragHandler = (element) => {
            const draggable = element.parent().draggable();
            draggable.on('dragmove', e => {
                e.preventDefault();
                selectGroup.value.clear();
                const { handler, box } = e.detail;

                let { x, y, x2, y2 } = box;

                handler.move(x, y);

                if (hoverRect.value) {
                    hoverRect.value.attr({'d': `M${x} ${y} H${x2} V${y2} H${x} Z`});
                }
            });
        }

        const removeArtboardElement = (element) => {
            const id = element.parent().id();
            element.parent().remove();
            selectGroup.value.clear();
            store.dispatch('sticker/removeLayer', id);
            store.dispatch('sticker/removeImagePreviews', id);
        }

        const alignElementHorizontally = (element, editorBackgroundPath) => {
            const bbox = editorBackgroundPath.bbox();
            const centerX = bbox.cx;

            const currentBBox = element.bbox();
            const currentWidth = currentBBox.width;

            // Calculate the new x to center the element horizontally
            const newX = centerX - (currentWidth / 2);

            // Set the new x value
            element.attr({ x: newX });
        }

        const alignElementVertically = (element, editorBackgroundPath) => {
            const bbox = editorBackgroundPath.bbox();
            const centerY = bbox.cy;

            const currentBBox = element.bbox();
            const currentHeight = currentBBox.height;

            // Calculate the new y to center the element vertically
            const newY = centerY - (currentHeight / 2);

            // Set the new y value
            element.attr({ y: newY });
        }

        return {
            /* VARIABLES */
            draw,
            defs,
            pattern,
            partGroupClipPath,
            preBottomLayerGroup,
            svgSheetGroup,
            containerGroup,
            containerChildGroup,
            editorBackgroundPath,
            selectLayerGroup,
            hoverRect,
            infoLayerGroup,
            infoTextHorizontal,
            infoTextVertical,
            selectGroup,
            isResizing,
            currentResizer,
            svgElement,
            selectedImage,
            selectButtonIds,
            activeElement,
            selectedTextElement,
            preSelected,
            backgroundColor,
            patternSize,
            svgViewPath,
            cutlines,
            shapeData,

            /* FUNCTIONS */
            initArtboard,
            addDragIcon,
            updateBackgroundImage,
            updatePatternImage,
            updateBackgroundColor,
            saveText,
            addClipart,
            addImage,
            addResizeHandlers,
            addHoverEffectHandler,
            addElementDragHandler,
            disableHoverOnDrag,
            removeArtboardElement,
            updateSelectedShape,
            getPathDimensions,
            alignElementHorizontally,
            alignElementVertically
        }
    }
}
</script>
<template>
    <svg id="svgElementFrontpaper" ref="svgElement" class="user-select-none">
        <defs>
            <pattern id="backgroundPattern" patternUnits="userSpaceOnUse" x="0" y="0"
                     :width="patternSize" :height="patternSize">
<!--                <image :href="selectedImage" :width="patternSize" :height="patternSize" />-->
                <rect width="100%" height="100%" :fill="backgroundColor" />
            </pattern>
            <filter id="drop_shadow" x="-50%" y="-50%" width="200%" height="200%" filterRes="1920"
                    style="display : inline">
                <feOffset result="offOut" in="SourceAlpha" dx="1.4456715393066406"
                          dy="1.4456715393066406">
                </feOffset>
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="0.7228357696533203">
                </feGaussianBlur>
                <feColorMatrix result="matrixOut" in="blurOut" type="matrix"
                               values="1 0 0 0 0.2, 0 1 0 0 0.2, 0 0 1 0 0.2, 0 0 0 0.5 0"></feColorMatrix>
                <feBlend in="SourceGraphic" in2="matrixOut" mode="normal"></feBlend>
            </filter>
            <clipPath id="partGroupClipPath" transform="matrix(1 0 0 1 0 0)"><path :d="svgViewPath" id="09915840056483567_path" transform="matrix(1 0 0 1 0 -0.105725)"></path></clipPath>
        </defs>
    </svg>
</template>
