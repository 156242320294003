<template>
    <div class="bg-custom-pink">
        <section class="top_bar d-flex justify-content-between">
            <div>
                <img class="landing_page--logo" :src="require('@/assets/img/utils/logo.png')" alt="">
            </div>
            <div>
                <div class="text-4"><i class="fa-solid fa-phone"></i> (406) 555-0120</div>
                <div class="text-4"><i class="fa-solid fa-envelope"></i> printpeelable@test.com</div>
            </div>
        </section>
        <section class="d-flex flex-column align-items-center justify-content-center p-2 p-lg-5">
            <h1 class="text-10 fw-bold text-center">Design & Order Your Dream Stickers</h1>
            <p class="fw-semibold mb-5 text-center">Express yourself with custom stickers. Design online, get them
                printed perfectly & delivered fast</p>
            <div class="position-relative">
                <img class="w-100" :src="require('@/assets/img/utils/landing_page_img.png')" alt="">
                <router-link :to="{ name: 'selection' }"
                    class="btn position-absolute bottom-0 start-50 translate-middle-x customized_btn"><i
                        class="fa-solid fa-paintbrush"></i> Customize Sticker</router-link>
            </div>
        </section>
        <section class="landing_footer bg-indigo-300 d-flex flex-column flex-lg-row justify-content-between mt-3 mt-lg-0">
            <div class="flex-1 d-flex justify-content-center justify-content-lg-start">
                <img class="landing_page--logo" :src="require('@/assets/img/utils/logo.png')" alt="">
            </div>
            <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-4 flex-1 my-3 my-lg-0 text-no-wrap">
                <router-link :to="{ name: 'selection' }"
                    class="text-decoration-none text-gray-900">Contact</router-link>
                <router-link :to="{ name: 'selection' }" class="text-decoration-none text-gray-900">About
                    Us</router-link>
                <router-link :to="{ name: 'selection' }" class="text-decoration-none text-gray-900">Custom
                    Sticker</router-link>
                <router-link :to="{ name: 'selection' }" class="text-decoration-none text-gray-900">Blogs</router-link>
                <router-link :to="{ name: 'selection' }"
                    class="text-decoration-none text-gray-900">Materials</router-link>
            </div>
            <div class="flex-1 d-flex justify-content-center justify-content-lg-end align-items-center gap-3">
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-facebook-f"></i></router-link>
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-instagram"></i></router-link>
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-youtube"></i></router-link>
                <router-link :to="{ name: 'selection' }" class="landing_page__footer--social"><i
                        class="fa-brands fa-x-twitter"></i></router-link>
            </div>
        </section>
    </div>
</template>

<style scoped>
.landing_page--logo {
    width: 140px;
}

.landing_page_title {
    font-weight: bold;
    font-size: 52px;
}

.landing_footer {
    padding: 10px 36px;
}

.landing_page__footer--social {
    width: 40px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: #212529;
    color: #F8F9FA;
    border-radius: 50%;
}

@media screen and (max-width: 567px) {
    .landing_page--logo {
    width: 80px;
}
}
</style>