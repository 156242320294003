<template>
    <div class="offcanvas-header pb-0">
        <h5 class="offcanvas-title text-4" id="offcanvasRightLabel">Cliparts</h5>
    </div>
    <div class="offcanvas-body pt-0">
        <p class="text-gray-300 text-3">Add cliparts to your design to </p>
        <div class="input-group my-3">
            <span class="input-group-text bg-white pe-0" id="search"><i class="bi bi-search"></i></span>
            <input type="text" class="form-control border-start-0" placeholder="Search for cliparts" aria-label="Search for cliparts"
                aria-describedby="search">
        </div>
        <div class="d-flex gap-2 customized_scrolled">
            <button :class="selectedType == 'trending' && 'active_clipart'" @click="changeClipartType('trending')" class="btn text-nowrap bg-gray-700 text-gray-300 border-1 py-1 border-gray-500 py-1">Trending</button>
            <button :class="selectedType == 'popular' && 'active_clipart'" @click="changeClipartType('popular')" class="btn text-nowrap bg-gray-700 text-gray-300 border-1 py-1 border-gray-500 py-1">Popular</button>
            <button :class="selectedType == 'love' && 'active_clipart'" @click="changeClipartType('love')" class="btn text-nowrap bg-gray-700 text-gray-300 border-1 py-1 border-gray-500 py-1">Love</button>
            <button :class="selectedType == 'handWritten' && 'active_clipart'" @click="changeClipartType('handWritten')" class="btn text-nowrap bg-gray-700 text-gray-300 border-1 py-1 border-gray-500 py-1">Hand Written</button>
        </div>
        <div class="row g-0">
            <div v-for="clipart in cliparts" :key="clipart.id" class="col-4 px-1 mb-1 aspect-ratio-1">
                <button class="bg-gray-700 rounded w-100 h-100 p-2" @click="add(clipart)">
                    <img class="img-fluid h-100 object-fit-contain" :src="clipart.thumbnail" alt="">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {clipartsData, handWritten, love, popular, trending} from '/public/jsons/clipart';
import {onMounted, ref} from 'vue';

export default {
    props: {
        addClipart: Function
    },
    setup(props) {
        const cliparts = ref([]);
        const selectedType = ref('trending');

        onMounted(() => {
            changeClipartType('trending');
        })

        const add = (clipart) => {
            props.addClipart(clipart)
        };
        const changeClipartType = type => {
            selectedType.value = type;
            if (type == 'trending') {
                cliparts.value = trending.map(trend => {
                    return clipartsData.find(clip => clip.id == trend);
                });
            } else if (type == 'popular') {
                cliparts.value = popular.map(trend => {
                    return clipartsData.find(clip => clip.id == trend);
                });
            } else if (type == 'love') {
                cliparts.value = love.map(trend => {
                    return clipartsData.find(clip => clip.id == trend);
                });
            } else if (type == 'handWritten') {
                cliparts.value = handWritten.map(trend => {
                    return clipartsData.find(clip => clip.id == trend);
                });
            }
        }
        return {
            cliparts,
            add,
            changeClipartType,
            selectedType
        }
    }
}
</script>

<style scoped>
.form-control:focus {
    box-shadow: none;
}

.active_clipart {
    background-color: #fff !important;
    color: #212529 !important;
    border-color: #fff !important;
}

.customized_scrolled {
    overflow-x: auto;
    padding-bottom: 2px;
    margin-bottom: 6px;
}

.customized_scrolled::-webkit-scrollbar {
    height: 4px;
}

.customized_scrolled::-webkit-scrollbar-track {
    background: transparent;
}

.customized_scrolled::-webkit-scrollbar-thumb {
    background: #ADB5BD;
    border-radius: 999px;
}

.customized_scrolled::-webkit-scrollbar-thumb:hover {
    background: #878a8e;
}
/* .customized_scrolled::-webkit-scrollbar-button {
    display: none;
} */
</style>
