<template>
    <div
        :class="[showingToolbar ? 'toolbar_offcanvas__expanded' : '', showingBottomBar ? 'bottom_offcanvas__expanded' : '', 'overflow-hidden bg-gray-200 sticker_mode_page--container']">
        <div class="side__toolbar--contianer">
            <div class="d-flex justify-content-center py-2 go_back_btn__container">
                <button @click="goBack" class="btn d-flex gap-1 text-gray-900 bg-indigo-300 fw-bold"><i
                        class="bi bi-arrow-left"></i> Back</button>
            </div>
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li v-if="windowSize < 568" class="nav-item">
                    <a href="#" :class="showingTools == 9 && 'active_tool'" class="nav-link py-3"
                        @click="showBottombarCanvas('stickerModeBottomPanel'); showingTools = 9">
                        <i class="bi bi-layers-half"></i><br />Uploads
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#" :class="showingTools == 1 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 1)">
                        <i class="bi bi-layers-half"></i><br />Arrange
                    </a>
                </li>
                <li>
                    <a href="#" :class="showingTools == 2 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 2)">
                        <i class="bi bi-cursor-text"></i><br />Text
                    </a>
                </li>
                <li>
                    <a href="#" :class="showingTools == 3 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 3)">
                        <i class="bi bi-palette"></i><br />Background
                    </a>
                </li>
                <li>
                    <a href="#" :class="showingTools == 4 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 4)">
                        <i class="bi bi-heart"></i><br />Clipart
                    </a>
                </li>
                <li class="px-2 hr_container" v-if="!backPaperEdit">
                    <hr class="border-3 text-white">
                </li>
                <li v-if="!backPaperEdit">
                    <a href="#" :class="showingTools == 5 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 5)">
                        <i class="bi bi-scissors"></i><br />Shapes
                    </a>
                </li>
                <li v-if="!backPaperEdit">
                    <a href="#" :class="showingTools == 6 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 6)">
                        <i class="bi bi-palette2"></i><br />Material
                    </a>
                </li>
                <li v-if="!backPaperEdit">
                    <a href="#" :class="showingTools == 7 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 7)">
                        <i class="bi bi-chevron-bar-expand"></i><br />Size
                    </a>
                </li>
                <li v-if="!backPaperEdit">
                    <a href="#" :class="showingTools == 8 && 'active_tool'" class="nav-link py-3"
                        @click="showToolbarCanvas('stickerModelTools', 8)">
                        <i class="bi bi-boxes"></i><br />Quantity
                    </a>
                </li>
            </ul>
        </div>
        <div class="offcanvas offcanvas-end text-white" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
            id="stickerModelTools" aria-labelledby="stickerModelToolsLabel">
            <ArrangeComponent :rearrangeLayers="rearrangeLayers" :imageList="imagePreviews" v-if="showingTools == 1" />
            <TextComponent :saveText="saveText" v-if="showingTools == 2" />
            <BackgroundComponent @bgColorChange="updateBackgroundColor" v-if="showingTools == 3" />
            <ClipartComponent :addClipart="addClipart" v-if="showingTools == 4" />
            <ShapesComponent v-if="showingTools == 5" @update-selected-shape="updateShape" />
            <MaterialComponent v-if="showingTools == 6" />
            <SizeComponent v-if="showingTools == 7" />
            <QuantityComponent v-if="showingTools == 8" />

            <button @click="hideToolbarCanvas('stickerModelTools')" class="panel_closer"><img
                    :src="require('@/assets/img/sticker-mode/panel_closer.png')" /></button>
        </div>
        <BottomBar :isFrontPaperUpload="isFrontPaperUpload" :isBackPaperUpload="isBackPaperUpload" :addImage="addImage" />

        <ul class="nav nav-tabs justify-content-center mt-4 border-0" id="stickerOptionTab">
            <li class="nav-item" role="presentation">
                <button class="nav-link rounded-start-pill" :class="{'active': !backPaperEdit}" id="sticker-tab"
                    type="button" @click="handleTabsDisplay('sticker')">Sticker</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link rounded-end-pill" :class="{'active': backPaperEdit}" id="backpaper-tab"
                    type="button" @click="handleTabsDisplay('backpaper')">Backpaper</button>
            </li>
        </ul>
        <div id="stickerOptionTabContent">
            <div class="" v-show="!backPaperEdit" tabindex="0">
                <div class="test_div" ref="artboard" id="artboard">
                    <FrontPaper ref="frontpaper"
                                @is-front-paper-upload="updateIsFrontPaperUpload"
                                :isFrontPaperUpload="isFrontPaperUpload"
                                :labelOffset="labelOffset"
                                :showToolbarCanvas="showToolbarCanvas"
                                :rearrangeLayers="rearrangeLayers" />
                </div>
            </div>
            <div class="" v-show="backPaperEdit" tabindex="0">
                <div class="test_div" id="backpaper">
                    <BackPaper ref="backpaper"
                               @is-back-paper-upload="updateIsBackPaperUpload"
                               :isBackPaperUpload="isBackPaperUpload"
                               :labelOffset="labelOffset"
                               :showToolbarCanvas="showToolbarCanvas" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import { Tooltip } from 'bootstrap';
import {ref, onMounted, inject, watch} from 'vue';
import { openCanvas, closeCanvas } from '@/assets/js/sticker-mode.js';
import ArrangeComponent from '@/components/Sticker-mode/Tools/ArrangeComponent.vue';
import TextComponent from '@/components/Sticker-mode/Tools/TextComponent.vue';
import BackgroundComponent from '@/components/Sticker-mode/Tools/BackgroundComponent.vue';
import ClipartComponent from '@/components/Sticker-mode/Tools/ClipartComponent.vue';
import ShapesComponent from '@/components/Sticker-mode/Tools/ShapesComponent.vue';
import MaterialComponent from '@/components/Sticker-mode/Tools/MaterialComponent.vue';
import SizeComponent from '@/components/Sticker-mode/Tools/SizeComponent.vue';
import QuantityComponent from '@/components/Sticker-mode/Tools/QuantityComponent.vue';
import BottomBar from '@/components/Sticker-mode/Bottombar/BottomBar.vue'
import FrontPaper from '@/components/Sticker-mode/FrontPaper.vue'
import BackPaper from '@/components/Sticker-mode/BackPaper.vue'
import '@svgdotjs/svg.draggable.js';
import { useStore } from 'vuex';
import { computed } from 'vue';
import {SVG} from "@svgdotjs/svg.js";

export default {
    components: {
        ArrangeComponent,
        TextComponent,
        BackgroundComponent,
        ClipartComponent,
        ShapesComponent,
        MaterialComponent,
        SizeComponent,
        QuantityComponent,
        BottomBar,
        FrontPaper,
        BackPaper
        // StickerPaper
    },
    setup() {
        const store = useStore();
        const frontpaper = ref(null);
        const backpaper = ref(null);
        const router = inject('router');
        const showingToolbar = ref(false);
        const showingBottomBar = computed(() => store.getters['sticker/getShowingBottomBar']);
        const showingTools = ref('');
        const showingTab = computed(() => store.getters['sticker/getShowingTab']);
        const windowSize = computed(() => store.getters['sticker/getWindowSize']);
        const backPaperEdit = computed(() => store.getters["stickerPaper/getBackPaperEdit"]);
        const labelOffset = ref(30);
        const isFrontPaperUpload = ref(false);
        const isBackPaperUpload = ref(false);
        const cutlines = ref(store.getters['stickerPaper/getCutlines']);

        /* Paper Variables */

        // Call init function on mounted
        onMounted(async () => {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
            setPreselectedValue();
            await store.dispatch('sticker/setWindowSize');
        });
        /* Sagor's Functions */
        const showToolbarCanvas = (id, activeToolbar) => {
            if (!showingToolbar.value) {
                openCanvas(id);
            }
            showingToolbar.value = true;
            showingTools.value = activeToolbar;
            if(windowSize.value < 568) {
                hideBottombarCanvas('stickerModeBottomPanel');
            }
        }
        const hideToolbarCanvas = (id = 'stickerModelTools') => {
            closeCanvas(id);
            showingToolbar.value = false;
            showingTools.value = '';
            store.dispatch("stickerPaper/setSelectedTextElement", {});
        }
        const showBottombarCanvas = (id) => {
            if (!showingBottomBar.value) {
                openCanvas(id);
            }
            store.dispatch('sticker/setShowingBottomBar', true);
            if(windowSize.value < 568) {
                hideToolbarCanvas('stickerModelTools');
            }
        }
        const hideBottombarCanvas = (id) => {
            closeCanvas(id);
            showingBottomBar.value = false;
            store.dispatch('sticker/setShowingBottomBar', false);
        }
        const setPreselectedValue = async () => {
            await store.dispatch('selection/setSelectedProduct', store.getters['selection/getPreselected'].selectedProduct.id);
            await store.dispatch('selection/setSelectedShape', store.getters['selection/getPreselected'].selectedShape);
            await store.dispatch('selection/setSelectedMaterial', store.getters['selection/getPreselected'].selectedMaterial);
            await store.dispatch('selection/setSelectedCoating', store.getters['selection/getPreselected'].selectedCoating);
            await store.dispatch('selection/setSelectedSize', store.getters['selection/getPreselected'].selectedSize);
            await store.dispatch('selection/setSelectedQuantity', store.getters['selection/getPreselected'].selectedQuantity);
        }

        const goBack = () => {
            router.go(-1);
        };

        const handleTabsDisplay = async (tabName) => {
            store.dispatch("stickerPaper/setBackPaperEdit", tabName === 'backpaper');
            await store.dispatch('sticker/setShowingTab', tabName);
            hideBottombarCanvas('stickerModeBottomPanel');
        }

        const rearrangeLayers = (isBackPaper, previousIndex, currentIndex) => {
            const elementId = !isBackPaper ? "svgElementFrontpaper" : "svgElementBackpaper";
            const mainElement = SVG(`#${elementId}`);
            let layers = mainElement.find(!isBackPaper ? '.svgElementPart' : '.svgElementPartBackpaper');
            let previousItem = layers[previousIndex];
            let currentItem = layers[currentIndex];
            if (currentIndex > previousIndex) {
                previousItem.insertAfter(currentItem);
            } if (currentIndex < previousIndex) {
                previousItem.insertBefore(currentItem);
            }
        }

        const saveText = (data, updatedTextId = null) => {
            if (backPaperEdit.value) {
                backpaper.value.saveText(data, updatedTextId)
            } else {
                frontpaper.value.saveText(data, updatedTextId)
            }
            hideToolbarCanvas();
        }

        const updateBackgroundColor = (color) => {
            if (backPaperEdit.value) {
                backpaper.value.updateBackgroundColor(color);
            } else {
                frontpaper.value.updateBackgroundColor(color);
            }
        }

        const addClipart = (clipart) => {
            if (backPaperEdit.value) {
                backpaper.value.addClipart(clipart);
            } else {
                frontpaper.value.addClipart(clipart);
            }
        }

        const addImage = () => {
            if (backPaperEdit.value) {
                backpaper.value.addImage();
            } else {
                frontpaper.value.addImage();
            }
        }

        const updateIsFrontPaperUpload = (val) => {
            isFrontPaperUpload.value = val;
        }

        const updateIsBackPaperUpload = (val) => {
            isBackPaperUpload.value = val;
        }

        const updateShape = (shape) => {
            const path = cutlines.value[shape.type];
            store.dispatch("stickerPaper/setSvgViewPath", path);//
            frontpaper.value.updateSelectedShape(path);
            backpaper.value.updateSelectedShape(path);
        }

        return {
            /* Sagor's Return */
            showToolbarCanvas,
            hideToolbarCanvas,
            showingTools,
            goBack,
            showingToolbar,
            showingBottomBar,
            handleTabsDisplay,
            showingTab,
            showBottombarCanvas,
            rearrangeLayers,
            frontpaper,
            backpaper,
            saveText,
            updateBackgroundColor,
            addClipart,
            addImage,
            backPaperEdit,
            // Add other variables here
            windowSize,
            labelOffset,
            isFrontPaperUpload,
            updateIsFrontPaperUpload,
            isBackPaperUpload,
            updateIsBackPaperUpload,
            updateShape,
            cutlines
        }
    }
}

</script>

<style scoped>
@import url('../../assets/css/font.css');

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background: #E9ECEF !important;
}

html {
    height: -webkit-fill-available;
    background: #E9ECEF !important;
}

main {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

.b-example-divider {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.sticker_mode_page--container {
    transition: all 0.3s ease-in-out;
}

.bi {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
}

.dropdown-toggle {
    outline: 0;
}

.side__toolbar--contianer .nav-flush .nav-link {
    border-radius: 0;
}

.btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;
}

.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #d2f4ea;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: #d2f4ea;
}

.scrollarea {
    overflow-y: auto;
}

.fw-semibold {
    font-weight: 600;
}

.lh-tight {
    line-height: 1.25;
}

.side__toolbar--contianer .nav-link {
    padding: 0;
    font-size: 12px;
    color: #E9ECEF;

}

.side__toolbar--contianer {
    z-index: 9999;
}

.side__toolbar--contianer .nav-pills .nav-link.active {
    background: #343A40;
}

.side__toolbar--contianer .nav-link:hover,
.side__toolbar--contianer .nav-link:focus,
.side__toolbar--contianer .nav-link:focus-visible,
.active_tool {
    background: #343A40;
    color: #E9ECEF;
}

/* Canvas */
.offcanvas {
    background: #343A40;
}

.panel_closer {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
}

.test_div {
    width: 100%;
    height: 100vh;
    background: var(--gray-200) !important;
    position: relative;
    overflow: hidden;
}

.preview_img_remove_btn:first-child:active {
    border-color: #DC3545 !important;
}

.mousehover {
    outline: 2px solid #000;
}
.sticker_mode_page--container .nav-tabs .nav-link,
.sticker_mode_page--container .nav-tabs .nav-item.show .nav-link {
    color: #212529 !important;
    background: #fff !important;
    font-weight: 500 !important;
    border: none;
    border-radius: 0;
}
.sticker_mode_page--container .nav-tabs .nav-link.active,
.sticker_mode_page--container .nav-tabs .nav-item.show .nav-link {
    background: #C6A8F6 !important;
}
</style>
