import { Tooltip } from 'bootstrap';
import { onMounted, inject } from 'vue';
import {} from '@/assets/js/sticker-mode.js';
import ArrangeComponent from '@/components/Sticker-mode/Tools/ArrangeComponent.vue';
import TextComponent from '@/components/Sticker-mode/Tools/TextComponent.vue';
import BackgroundComponent from '@/components/Sticker-mode/Tools/BackgroundComponent.vue';
import ClipartComponent from '@/components/Sticker-mode/Tools/ClipartComponent.vue';
import ShapesComponent from '@/components/Sticker-mode/Tools/ShapesComponent.vue';
import MaterialComponent from '@/components/Sticker-mode/Tools/MaterialComponent.vue';
import SizeComponent from '@/components/Sticker-mode/Tools/SizeComponent.vue';
import QuantityComponent from '@/components/Sticker-mode/Tools/QuantityComponent.vue';
import {SVG} from "@svgdotjs/svg.js";
import resizeIcon from '@/assets/img/resize.svg';

export default {
    components: {
        ArrangeComponent,
        TextComponent,
        BackgroundComponent,
        ClipartComponent,
        ShapesComponent,
        MaterialComponent,
        SizeComponent,
        QuantityComponent,
    },
    data() {
        return {
            selectedImage: 'https://d6ce0no7ktiq.cloudfront.net/images/web/editor/prismatic_bg.png',
            patternSize: 54.842,
            patternSizeX: 54.842,
            patternSizeY: 54.842,
            viewPortWidth: '100%',
            viewPortHeight: '100%',
            viewBoxWidth: 1200,
            viewBoxHeight: 1200,
            svgViewPath: `M0,0 L0,400 L400,400 L400,0 z`,
            widthInMM: 0,
            heightInMM: 0,
            viewBox: '-5 -5 500 500',
            svgPath: '',
            xPosition: 0,
            yPosition: 0,
            svgWidth: 500,
            svgHeight: 500,
            artBoardWidth: 500,
            artBoardHeight: 500,
            isMounted: false,
            elementSize: 500,
        };
    },
    watch: {
        selectedImage(newImage) {
            const patternImage = this.$refs.svgElement.querySelector('pattern image');
            if (patternImage) {
                patternImage.setAttribute('href', newImage);
            }
        }
    },
    methods: {
        initArtboard: function () {
            //this.svgViewPath = `M0,0 L0,${this.viewBoxWidth} L${this.viewBoxWidth},${this.viewBoxHeight} L${this.viewBoxHeight},0 z`;
            this.draw = SVG(this.$refs.svgElement).attr({
                'size': `${this.viewPortWidth}, ${this.viewPortHeight}`,
                'viewBox': `-100 -100 ${this.viewBoxWidth} ${this.viewBoxHeight}`,
                'preserveAspectRatio': 'xMidYMid meet',
                'fill': '#e7e7e7',
                'align': "center"
            });

            this.defs = this.draw.defs();
            this.pattern = this.defs.findOne('#backgroundPattern');
            this.editorBackgroundPath = this.draw.path(this.svgViewPath).id('editorBackground').fill(this.pattern);
            this.selectLayerGroup = this.draw.group().id('selectLayerGroup');
            this.hoverRect = null;
            this.editorBackgroundPath.on(['mouseover', 'mouseout'], (e) => {
                if (e.type === 'mouseover') {
                    if (!this.hoverRect) {
                        this.hoverRect = this.selectLayerGroup
                            .path(this.svgViewPath)
                            //.path("M 0 21.6851 C -1.46668e-15 9.70874 9.70874 2.20002e-15 21.6851 0 C 21.6851 0 122.882 0 122.882 0 C 134.858 7.33339e-16 144.567 9.70874 144.567 21.6851 C 144.567 21.6851 144.567 122.882 144.567 122.882 C 144.567 134.858 134.858 144.567 122.882 144.567 C 122.882 144.567 21.6851 144.567 21.6851 144.567 C 9.70874 144.567 1.46668e-15 134.858 0 122.882 C 0 122.882 0 21.6851 0 21.6851")
                            .stroke({ color: 'grey', width: 1 })
                            .fill('none');
                            //.move(225, 75);
                    }
                } else if (e.type === 'mouseout') {
                    if (this.hoverRect) {
                        this.hoverRect.remove();
                        this.hoverRect = null;
                    }
                }
            });

            this.infoLayerGroup = this.draw.group().id('infoLayer').attr({
                'transform': 'matrix(1 0 0 1 0 0)'
            });
            this.infoText1 = this.infoLayerGroup.text('1').id('infoText1').x(200).y(-30).attr({
                'fill': '#858585',
                'font-weight': 'bold',
                'space': 'preserve',
                'font-size': "10",
                'text-anchor': "middle",
                'transform': "matrix(1 0 0 1 0 0)"
            });
            this.infoText2 = this.infoLayerGroup.text('2').id('infoText2').x(420).y(200).attr({
                'fill': '#858585',
                'font-weight': 'bold',
                'space': 'preserve',
                'font-size': "10",
                'text-anchor': "middle",
                'transform': "matrix(1 0 0 1 0 0)"
            });

            // Add selectGroup
            this.selectGroup = this.selectLayerGroup.group().id('selectGroup').attr({
                transform: 'matrix(1 0 0 1 0 0)',
                style: 'display: none;',
            });

            this.selectGroup.rect(400, 400).id('selectGroupSelectedMarker').x(0).y(0)
                .attr({
                    fill: 'none',
                    stroke:"black",
                    strokeWidth:".2"
                });

            // Add corners and sides
            const corners = ['NW', 'NE', 'SE', 'SW'];
            const sides = ['N', 'E', 'S', 'W'];
            corners.forEach(corner => {
                this.addDragIcon(corner, true);
            });
            sides.forEach(side => {
                this.addDragIcon(side, false);
            });

            // Event listener to show selectGroup
            this.editorBackgroundPath.on('click', (e) => {
                e.stopPropagation();
                this.selectGroup.show();
            });

            // Event listener to hide selectGroup when clicking outside
            this.draw.on('mousedown', (e) => {
                if (e.target.id !== 'editorBackground') {
                    this.selectGroup.hide();
                }
                /*if (!this.$refs.svgElement.contains(e.target)) {
                    this.selectGroup.hide();
                }*/
            });
        },
        // eslint-disable-next-line no-unused-vars
        addDragIcon(position, isCorner) {
            const idPrefix = `select${position}Icon`;
            const icon = this.selectGroup.group().id(idPrefix).attr({
                class: `svgSelect${position}`,
                'pointer-events': 'all',
            });

            // Calculate the position based on viewBox coordinates
            const iconSize = 20;
            let x = 0, y = 0;
            let cursor = '';
            let dragIconRotate = 0;
            switch (position) {
                case 'NW':
                    x = -10;
                    y = -10;
                    cursor = 'nw-resize';
                    dragIconRotate = 315;
                    break;
                case 'NE':
                    x = 390;
                    y = -10;
                    cursor = 'ne-resize';
                    dragIconRotate = 45;
                    break;
                case 'SE':
                    x = 390;
                    y = 390;
                    cursor = 'se-resize';
                    dragIconRotate = 135;
                    break;
                case 'SW':
                    x = -10;
                    y = 390;
                    cursor = 'sw-resize';
                    dragIconRotate = 225;
                    break;
                case 'N':
                    x = 195;
                    y = -10;
                    cursor = 'n-resize';
                    dragIconRotate = 0;
                    break;
                case 'E':
                    x = 390;
                    y = 195;
                    cursor = 'e-resize';
                    dragIconRotate = 90;
                    break;
                case 'S':
                    x = 195;
                    y = 390;
                    cursor = 's-resize';
                    dragIconRotate = 180;
                    break;
                case 'W':
                    x = -10;
                    y = 195;
                    cursor = 'w-resize';
                    dragIconRotate = 270;
                    break;
            }
            icon.attr({
                fill: '#2a2a2a',
                cursor: cursor
            })

            icon.attr({
                transform: `translate(${x}, ${y})`,
            });

            // Create the clip path
            const clipPath = this.defs.clip().id(`${idPrefix}IconClipPath`);
            clipPath.add(this.draw.rect(iconSize, iconSize));

            // Apply the clip path to the icon
            icon.rect(iconSize, iconSize).attr({
                fill: '#575757',
                rx: 5,
                ry: 5,
                class: 'svgIcon',
                id: `${idPrefix}IconBackground`,
                'pointer-events': 'all',
                'clip-path': `url(#${idPrefix}IconClipPath)`
            });

            const iconSecondGroup = icon.group().attr({
                'pointer-events': 'none',
                'clip-path': `url(#${idPrefix}IconClipPath)`,
                transform: 'matrix(1 0 0 1 0 0)',
            });

            /*iconSecondGroup.image('https://d6ce0no7ktiq.cloudfront.net/images/web/sprites/editor-icons.svg').attr({
                transform: 'matrix(1 0 0 1 0 0)',
                x: 0,
                y: 0,
                width: 550,
                height: 550,
                id: `${idPrefix}IconImage`,
            });*/

            iconSecondGroup.image(resizeIcon).attr({
                transform: `matrix(1 0 0 1 0 0) rotate(${dragIconRotate})`,
                x: 0,
                y: 0,
                width: 20,
                height: 20,
                id: `${idPrefix}IconImage`,
            });
        },
        centerElements() {
            if (!this.isMounted) return;

            const svgElement = this.$refs.svgElement;
            const artboard = this.$refs.artboard;

            if (!svgElement || !artboard) return;

            const elementSize = this.elementSize;
            const svgWidth = artboard.clientWidth;
            const svgHeight = artboard.clientHeight;

            const leftOffset = (svgWidth - elementSize) / 2;
            const topOffset = (svgHeight - elementSize) / 2;

            const elementsToCenter = ['svgSheetPath', 'editorBackgroundGroup', 'selectLayer', 'container', 'infoLayer', 'backgroundPartBackground'];
            elementsToCenter.forEach(id => {
                const element = svgElement.getElementById(id);
                if (element) {
                    element.setAttribute('transform', `translate(${leftOffset}, ${topOffset})`);
                }
            });
        },
        updateBackgroundImage(imagePath) {
            this.selectedImage = imagePath;
            this.updatePatternImage();
        },
        updatePatternImage(imagePath) {
            this.draw.find('#backgroundPattern image').attr('href', imagePath);
        },
        resizeElement(element) {
            // Add event listeners for resizing
            let isResizing = false;
            let originalWidth = 0;
            let originalHeight = 0;
            let originalX = 0;
            let originalY = 0;

            element.on('mousedown', (event) => {
                isResizing = true;
                originalWidth = element.width();
                originalHeight = element.height();
                originalX = event.clientX;
                originalY = event.clientY;
            });

            element.on('mousemove', (event) => {
                if (isResizing) {
                    const newWidth = originalWidth + (event.clientX - originalX);
                    const newHeight = originalHeight + (event.clientY - originalY);

                    this.draw.size(newWidth, newHeight);
                }
            });

            element.on('mouseup', () => {
                isResizing = false;
            });
        },
    },
    mounted() {
        this.initArtboard();
        this.selectedImage = "https://d6ce0no7ktiq.cloudfront.net/images/web/editor/prismatic_bg.png";
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.centerElements);
    },
    setup() {
        const router = inject('router');
        onMounted(() => {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            })
        });

        const goBack = () => {
            router.go(-1);
        }

        return {
            goBack,
        }
    }
}
